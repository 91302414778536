export const priceInfoFunctionalDiagnostics = [
    {
        code: "21.1",
        name: "Регистрация, расшифровка, описание  электрокардиограммы",
        price: "550",
    },
    {
        code: "21.2",
        name: "Регистрация ЭКГ с физ. нагрузкой и расшифровка",
        price: "950",
    },
    {
        code: "21.3",
        name: "Регистрация ЭКГ на дому пациента (в Красноармейском районе), расшифровка, описание  электрокардиограммы",
        price: "950",
    },
    {
        code: "21.3",
        name: "Электроэнцефалография (ЭЭГ)",
        price: "1000",
    },
    {
        code: "21.4",
        name: "Электроэнцефолография при прохождении мед. осмотров",
        price: "1000",
    },
    {
        code: "21.5",
        name: "Спирометрия с пробами",
        price: "650",
    },
    {
        code: "21.6",
        name: "Спирометрия без проб",
        price: "650",
    },
    {
        code: "21.7",
        name: "Суточное мониторирование артериального давления СМАД",
        price: "1350",
    },
    {
        code: "21.8",
        name: "Суточное мониторирование артериального давления СМАД  ТОЛЬКО для ДМС",
        price: "1100",
    },
    {
        code: "21.9",
        name: "Суточное мониторирование ЭКГ (ХОЛТЕР)  ТОЛЬКО для ДМС",
        price: "1100",
    },
    {
        code: "21.10",
        name: "Суточное мониторирование артериально давления СМАД СРОЧНО, расшифровка до 3 сут",
        price: "1600",
    },
    {
        code: "21.11",
        name: "Суточное мониторирование ЭКГ (ХОЛТЕР)",
        price: "1350",
    },
    {
        code: "21.12",
        name: "Суточное мониторирование ЭКГ (ХОЛТЕР) БЕЗ УСТАНОВКИ ХОЛТЕРА",
        price: "1100",
    },
    {
        code: "21.13",
        name: "Установка холтера",
        price: "250",
    },
    {
        code: "21.14",
        name: "Суточное мониторирование ЭКГ по Холтеру СРОЧНО до 3 сут	",
        price: "1700",
    },
    {
        code: "21.15",
        name: "	Электроэнцефалография (ЭЭГ)",
        price: "1100",
    }
];
