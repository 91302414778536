import React from "react";
import { InformationContainerComponent } from "../../../components/InformationContainerComponent/InformationContainerComponent";

export const New7 = () => {
  return (
    <div className="specific_new_page">
      <InformationContainerComponent header="Ультразвуковое исследование в гинекологии">
        <div className="NewPage__text">
          <h3 className="NewPage__text">
            В гинекологии есть два заболевания, развитие которых начинается
            постепенно и совершенно бессимптомно. Печально то, что впоследствии
            лечение этих заболеваний в их запущенной стадии чаще всего требует
            хирургического вмешательства, может снижать репродуктивный потенциал
            и быть достаточно дорогим.
          </h3>
          <h3 className="NewPage__text">
            Это эндометриоидные кисты яичников и миома матки.
          </h3>
          <h3 className="NewPage__text">
            Чаще всего эти заболевания выявляются ближе к 28-30 годам, но
            начинают развиваться с 20 лет. С 20 до 30 лет женщины в основном
            ходят к гинекологу решать вопросы с плохими выделениями, а УЗИ
            выполняется реже, чем обычный осмотр гинеколога, не позволяющий
            выявить начальные стадии этих заболеваний. Раннее выявление кист и
            миом, когда их размер небольшой, позволяет стабилизировать их, и не
            дать расти дальше. Чаще всего небольшие миомы и кисты не влияют на
            возможность беременности и вынашивания, поэтому выявленную в
            начальной стадии ситуацию можно “заморозить”.
          </h3>
          <h3 className="NewPage__text">
            Для своевременной диагностики таких заболеваний проходите УЗИ
            органов малого таза 1 раз в год - это не больно, не сложно, не долго
            и даже не дорого, но если вы случайно поймаете маленькую
            эндометриоидную кисту яичника или узел миомы - вы сможете радикально
            изменить сценарий вашей жизни в позитивную сторону.
          </h3>
        </div>
      </InformationContainerComponent>
    </div>
  );
};
