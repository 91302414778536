export const priceInfoOtherServices = [
    {
        code: "24.1",
        name: "Выезд врача на дом, красноармейский район",
        price: "1800",
    },
    {
        code: "24.2",
        name: "Выезд врача на дом за пределами района, Св яр, Ивановка, Кир.",
        price: "3600",
    },
    {
        code: "24.3",
        name: "Выезд врача на дом, Сов., Вор., Центр.",
        price: "5400",
    },
    {
        code: "24.4",
        name: "Справка для оформления путевки",
        price: "500",
    },
    {
        code: "24.5",
        name: "Справка для оформления санаторно курортной карты",
        price: "500",
    },
    {
        code: "24.6",
        name: "Выписка из амбулаторной карты",
        price: "300",
    },
    {
        code: "24.7",
        name: "Выдача справки для посещения бассейна",
        price: "500",
    }
];
