export const priceInfoNeurologist = [
  {
    code: "6.1",
    name: "Прием первичный врача - невролога (детский/взрослый)",
    price: "950",
  },
  {
    code: "6.2",
    name: "Прием повторный врача - невролога (детский/взрослый)",
    price: "900",
  },
  {
    code: "6.3",
    name: "Консультация врача - невролога для коррекции лечения",
    price: "600",
  },
  {
    code: "6.4",
    name: "Блокада паравертебральная. Без стоимости препаратов. Выполнение врачом неврологом",
    price: "600",
  },
];
