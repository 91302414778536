export const priceInfoMassage = [
    {
        code: "8.1",
        name: "Массаж спины медицинский",
        price: "880",
    },
    {
        code: "8.2",
        name: "Массаж спины медицинский детям",
        price: "440",
    },
    {
        code: "8.3",
        name: "Массаж живота медицинский",
        price: "550",
    },
    {
        code: "8.4",
        name: "Массаж живота медицинский детям",
        price: "300",
    },
    {
        code: "8.5",
        name: "Массаж шейно-воротниковой зоны медицинский",
        price: "550",
    },
    {
        code: "8.6",
        name: "Массаж шейно-воротниковой зоны медицинский детям",
        price: "300",
    },
    {
        code: "8.7",
        name: "Массаж шейно-грудного отдела позвоночника медицинский",
        price: "550",
    },
    {
        code: "8.8",
        name: "Массаж шейно-грудного отдела позвоночника медицинский детям",
        price: "300",
    },
    {
        code: "8.9",
        name: "Массаж грудного отдела позвоночника медицинский",
        price: "440",
    },
    {
        code: "8.11",
        name: "Массаж грудного отдела позвоночника медицинский детям",
        price: "250",
    },
    {
        code: "8.12",
        name: "Массаж пояснично-крестцовой области медицинский",
        price: "550",
    },
    {
        code: "8.13",
        name: "Массаж пояснично-крестцовой области медицинский детям",
        price: "300",
    },
    {
        code: "8.14",
        name: "Массаж пояснично-крестцового отдела и верхняя ягодичная область",
        price: "650",
    },
    {
        code: "8.15",
        name: "Массаж пояснично-крестцового отдела и верхняя ягодичная область детям",
        price: "350",
    },
    {
        code: "8.16",
        name: "Массаж верхней конечности медицинский",
        price: "500",
    },
    {
        code: "8.17",
        name: "Массаж верхней конечности медицинский детям",
        price: "300",
    },
    {
        code: "8.18",
        name: "Массаж нижней конечности медицинский",
        price: "550",
    },
    {
        code: "8.19",
        name: "Массаж нижней конечности медицинский детям",
        price: "300",
    },
    {
        code: "8.20",
        name: "Массаж плечевоого пояса медицинский",
        price: "550",
    },
    {
        code: "8.21",
        name: "Массаж плечевоого пояса медицинский детям",
        price: "300",
    },
    {
        code: "8.22",
        name: "Массаж  области головы медицинский",
        price: "550",
    },
    {
        code: "8.23",
        name: "Массаж  области головы медицинский детям",
        price: "300",
    },
    {
        code: "8.24",
        name: "Общий массаж медицинский",
        price: "1550",
    },
    {
        code: "8.25",
        name: "Общий массаж медицинский  детям",
        price: "800",
    },
    {
        code: "8.26",
        name: " Массаж коленного сустава",
        price: "450",
    },
];
