export const SpecialistsInfoUltrasoundDiagnostics = [
    "Асеева Татьяна Александровна – УЗД",
    "Безверхий Алексей Владимирович – УЗД",
    "Летуновская Ольга Викторовна – УЗД",
    "Литиненко Мадина Геннадьевна – УЗД",
    "Медведева Елена Владимировна – УЗД",
    "Павлова Анна Эдуардовна – УЗД",
    "Симонова Марина Геннадьевна – УЗД",
    "Шапошникова Наталья Владимировна – УЗД",
];
