export const qualificationsInfo = [
    {
        name: "Асеева Татьяна Александровна",
        speciality: "функциональный диагност",
        yearSity: "2017, Волгоград",
        time: "5 лет",
    },
    {
        name: "Бакумец Валерий Игнатьевич",
        speciality: "уролог",
        yearSity: "2020, Москва",
        time: "5 лет",
    },
    {
        name: "Безверхий Алексей Владимирович",
        speciality: "онколог",
        yearSity: "2018, Волгоград",
        time: "5 лет",
    },
    {
        name: "Григорьева Лариса Вячеславовна",
        speciality: "хирург",
        yearSity: "2021, Волгоград",
        time: "5 лет",
    },
    {
        name: "Кузнецова Наталья Александровна",
        speciality: "травматолог-ортопед",
        yearSity: "2019, Волгоград",
        time: "5 лет",
    },
    {
        name: "Летуновская Ольга Викторовна",
        speciality: "акушер-гинеколог",
        yearSity: "2017, Волгоград",
        time: "5 лет",
    },
    {
        name: "Летуновская Ольга Викторовна",
        speciality: "врач узд",
        yearSity: "2017, Волгоград",
        time: "5 лет",
    },
    {
        name: "Литвиненко Мадина Геннадьевна",
        speciality: "функциональный диагност",
        yearSity: "2016, Москва",
        time: "5 лет",
    },
    {
        name: "Медведева Е. В.",
        speciality: "",
        yearSity: "2020, Саратов",
        time: "5 лет",
    },
    {
        name: "Мельникова Ольга Андреевна",
        speciality: "дерматовенеролог",
        yearSity: "2018, Волгоград",
        time: "5 лет",
    },
    {
        name: "Назарук Александр Сергеевич",
        speciality: "хирург",
        yearSity: "2018, Волгоград",
        time: "5 лет",
    },
    {
        name: "Ногина Наталья Вячеславовна	",
        speciality: "невролог	",
        yearSity: "2018, Волгоград",
        time: "5 лет",
    },
    {
        name: "Орешина Елена Юрьевна",
        speciality: "кардиолог",
        yearSity: "2017, Волгоград",
        time: "5 лет",
    },
    {
        name: "Острянина Елена Валерьевна",
        speciality: "кардиолог",
        yearSity: "2019, Волгоград",
        time: "5 лет",
    },
    {
        name: "Павлова Анна Эдуардовна",
        speciality: "врач узд	",
        yearSity: "2020, Екатеринбург",
        time: "5 лет",
    },
    {
        name: "Петрухина Галина Геннадиевна",
        speciality: "оториноларинголог",
        yearSity: "2019, Волгоград",
        time: "5 лет",
    },
    {
        name: "Полякова Л. А",
        speciality: "",
        yearSity: "2017, Волгоград",
        time: "5 лет",
    },
    {
        name: "Полякова О. С.",
        speciality: "функциональный диагност",
        yearSity: "2016, Волгоград",
        time: "5 лет",
    },
    {
        name: "Симененко Дмитрий Геннадьевич",
        speciality: "невролог",
        yearSity: "2021, Волгоград",
        time: "5 лет",
    },
    {
        name: "Симонова Марина Геннадьевна",
        speciality: "врач функциональной диагностики",
        yearSity: "2020, Волгоград",
        time: "5 лет",
    },
    {
        name: "Творогова Лариса Георгиевна",
        speciality: "терапевт",
        yearSity: "2020, Волгоград",
        time: "5 лет",
    },
    {
        name: "Толкунова Ирина Валерьевна",
        speciality: "терапевт",
        yearSity: "2021, Волгоград",
        time: "5 лет",
    },
    {
        name: "Титовская Надежда Александровна",
        speciality: "кардиолог",
        yearSity: "2020, Новосибирск",
        time: "5 лет",
    },
    {
        name: "Филиппова Юлия Борисовна",
        speciality: "эндокринолог",
        yearSity: "2020, Москва",
        time: "5 лет",
    },
    {
        name: "Шапошникова Наталья Владмировна	",
        speciality: "невролог",
        yearSity: "2018, Волгоград",
        time: "5 лет",
    }
];
