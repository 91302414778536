export const priceInfoRheumatologist = [
    {
        code: "14.1",
        name: "Первичный прием врача ревматолога",
        price: "1000",
    },
    {
        code: "14.2",
        name: "Повторный прием врача ревматолога",
        price: "900",
    }
    ,
    {
        code: "14.2",
        name: "Консультация врача ревматолога для коррекции лечения",
        price: "600",
    }
];
