export const priceInfoDermatologist = [
    {
        code: "4.1",
        name: "Первичный прием врача дерматовенеролога",
        price: "900",
    },
    {
        code: "4.2",
        name: "Повторный прием врача дерматовенеролога",
        price: "750",
    },
    {
        code: "4.3",
        name: "Консультация врача дерматолога для коррекции лечения",
        price: "600",
    },
    {
        code: "4.4",
        name: "Удаление контагиозного моллюска (1 единица)",
        price: "750",
    },
    {
        code: "4.5",
        name: "Удаление контагиозных моллюсков свыше 5 элементов",
        price: "1000",
    },
    {
        code: "4.6",
        name: "Криодеструкция 1 элемент",
        price: "500",
    },
    {
        code: "4.7",
        name: "Криодеструкция 2 элемента",
        price: "700",
    },
    {
        code: "4.8",
        name: "Криодеструкция 3 элемента",
        price: "800",
    },
];
