export const priceInfoUrologist = [
    {
        code: "19.1",
        name: "	Первичный прием врача уролога",
        price: "1000",
    },
    
    {
        code: "19.2",
        name: "Повторный прием врача уролога",
        price: "950",
    },
    {
        code: "19.3",
        name: "Консультация врача уролога для коррекции лечения",
        price: "600",
    },
    {
        code: "19.4",
        name: "Массаж предстательной железы",
        price: "650",
    },
    {
        code: "19.5",
        name: "Получение сока простаты",
        price: "500",
    },
    {
        code: "19.6",
        name: "Интракавернозная инъекция",
        price: "250",
    },
    {
        code: "19.7",
        name: "Взятие мазка из уретры",
        price: "400",
    },
    {
        code: "19.9",
        name: "Катетеризация мочевого пузыря",
        price: "1000",
    },
    {
        code: "19.10",
        name: "Замена цистостомического дренажа (дренаж пациента)",
        price: "1000",
    },
    {
        code: "19.11",
        name: "Замена цистостомического дренажа",
        price: "1150",
    }
];
