export const priceInfoColoproctologist = [
    {
        code: "7.1",
        name: "Первичный прием врача колопроктолога",
        price: "1300",
    },
    {
        code: "7.2",
        name: "Повторный прием врача колопроктолога",
        price: "1100",
    },
    {
        code: "7.3",
        name: "Консультация врача колопроктолога для коррекции лечения",
        price: "600",
    },
    {
        code: "7.4",
        name: "Ректосигмоскопия с использованием одноразового инструмента",
        price: "450",
    },
    {
        code: "7.5",
        name: "Биопсия",
        price: "550",
    }
];
