export const priceInfoEndocrinologist = [
    {
        code: "23.1",
        name: "	Прием первичный врача — эндокринолога",
        price: "1000",
    },
    {
        code: "23.2",
        name: "Прием повторный врача — эндокринолога",
        price: "900",
    },
    {
        code: "23.3",
        name: "Консултьтация врача эндокринолога для коррекции лечения",
        price: "600",
    }
];
