import alkhaphar from '../../assets/images/doctors/alkhaphar.jpg'
import aseeva from '../../assets/images/doctors/aseeva.jpg'
import bezverhiy from '../../assets/images/doctors/bezverhiy.jpg'
import biryukova from '../../assets/images/doctors/biryukova.jpg'
import chupryna from '../../assets/images/doctors/chupryna.jpg'
import noName from '../../assets/images/doctors/noName.jpg'
import filippova from '../../assets/images/doctors/filippova.jpg'
import gaivoronskayu from '../../assets/images/doctors/gaivoronskayu.jpg'
import grigorieva from '../../assets/images/doctors/grigorieva.jpg'
import isaeva from '../../assets/images/doctors/isaeva.jpg'
import kuznetsovael from '../../assets/images/doctors/kuznetsovael.jpg'
import letunovskaya from '../../assets/images/doctors/letunovskaya.jpg'
import litvinenko from '../../assets/images/doctors/litvinenko.jpg'
import matrosov from '../../assets/images/doctors/matrosov.jpg'
import nazarova from '../../assets/images/doctors/nazarova.jpg'
import nazaruk from '../../assets/images/doctors/nazaruk.jpg'
import naumenko from '../../assets/images/doctors/naumenko.jpg'
import noginaNV from '../../assets/images/doctors/noginaNV.jpg'
import ostryanina from '../../assets/images/doctors/ostryanina.jpg'
import pavlova from '../../assets/images/doctors/pavlova.jpg'
import petruhina from '../../assets/images/doctors/petruhina.jpg'
import savushkin from '../../assets/images/doctors/savushkin.jpg'
import shaposhnikova from '../../assets/images/doctors/shaposhnikova.jpg'
import subbotina from '../../assets/images/doctors/subbotina.jpg'
import titovskaya from '../../assets/images/doctors/titovskaya.jpg'
import tolkunova from '../../assets/images/doctors/tolkunova.jpg'
import tvorogova from '../../assets/images/doctors/tvorogova.jpg'
import melnik from '../../assets/images/doctors/melnik.jpg'
import oreshina from '../../assets/images/doctors/oreshina.jpg'
export const doctors = [
    {
        image: alkhaphar,
        name: "Альхафар Халед Алиевич",
        speciality: "Врач акушер-гинеколог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["17 лет (в 2008 г. присвоена 1 кв. кат.)"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: [
                    "Волгоградский Государственный Медицинский Институт по специальности «лечебное дело» (1996 г.);",
                    "Волгоградская Медицинская Академия (клиническая ординатура) по специальности «акушерство и гинекология» (1999 г.).",
                ],
            },
            {
                header: "Повышение квалификации:",
                description: [
                    "— «Казанская государственная медицинская академия», по теме «Эндохирургия в гинекологии» в 2005 г.",
                    "— ООО Многопрофильный Учебный Центр Дополнительного профессионального образования «Образовательный стандарт», ультразвуковоая диагностика, в 2019 г.",
                    "— «ФГБОУВО «ВГМУ», акушерство и гинекология, в 2019 г.",
                ],
            },
        ],
    },
    {
        image: aseeva,
        name: "Асеева Татьяна Александровна",
        speciality: "Врач ультразвуковой диагностики",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["16 лет"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: [
                    "ВолГМУ, специальность: функциональная диагностика",
                ],
            },
            {
                header: "Профессиональные навыки, применяемые методы диагностики в клинике «Эксперт»:",
                description: [
                    "ЭКГ-диагностика, УЗИ-диагностика: опорно-двигательного аппарата и заболеваний суставов; УЗИ сосудов шеи и головы (дуплексное сканирование брахиоцефальных сосудов)",
                ],
            },
            {
                header: "Список заболеваний, которые диагностирует врач:",
                description: [
                    "Заболевания опорно-двигательного аппарата: воспалительные, деструктивные, дегенеративные и травматические поражения суставов у взрослых и детей. Сердечно-сосудистые – заболевания, в том числе врожденные и приобретенные пороки и аномалии развития сердца и сосудов у взрослых и детей.",
                ],
            },
        ],
    },
    {
        image: bezverhiy,
        name: "Безверхий Алексей Владимирович",
        speciality: "Врач онколог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["29 лет"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: [
                    "ГБОУ ДПО «Российская медицинская академия последипломного образования», специальность: онкология",
                ],
            },
            {
                header: "Повышение квалификации:",
                description: [
                    "— 2001 г. – переквалификация по программе «Онкология» (РМАПО)",
                    "2016 г. – Сертификат специалиста по специальности «Онкология» (ГБОУ ДПО «Российская медицинская академия последипломного образования» - Онкология)",,
                ],
            },
            {
                header: "Список заболеваний, которые диагностирует врач:",
                description: [
                    "лечение, диагностика и профилактические мероприятия образований злокачественного или доброкачественного характера.",
                ],
            
            },
        ],
    },
    {
        image: biryukova,
        name: "Бирюкова Ирина Александровна",
        speciality: "Врач невролог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["24 года"],
            },
            {
                header: "Квалификационная категория:",
                description: ["Высшая по специальности «Неврология» (2014 г.)"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: [
                    "Волгоградский медицинский институт, специальность: невропатология",     
                ],
            },
            {
                header: "Образование:",
                description: [
                    "Волгоградский медицинский институт (1993 г.)",     
                ],
            },
            {
                header: "Повышение квалификации:",
                description: [
                    "2013 г. – повышение квалификации на кафедре нервных болезней ВолГМУ по тематике: «Актуальные вопросы неврологии»",
                    "2018 г. – Сертификат специалиста по специальности «Неврология»",
                   
                ],
            },
            {
                header: "Список заболеваний, которые диагностирует и лечит врач:",
                description: [
                    "Вeгeтococудиcтaя диcтoния, нeвpиты и paдикулиты, профилактика инсульта и реабилитация после него, бoкoвoй aмиoтpoфичecкий cклepoз, а также рeaбилитaция пocлe тpaвм, oпepaтивныx вмeшaтeльcтв, тяжелыx бoлeзнeй, зaтpaгивaющиx нepвную cиcтeму.",
                    "Как дeтcкий нeвpoлoг, проводит лeчeниe и кoppeкцию нapушeнныx функций: пpи oтcтaвaнии в умcтвeннoм и физичecкoм paзвитии нeвpoлoгичecкoгo xapaктepa, пpи вpoждённыx aнoмaлияx paзвития пoзвoнoчникa и чepeпa, пpи дeтcкoм цepeбpaльнoм пapaличe.",
                   
                ],
            },
        
        ],
    },
    {
        image: gaivoronskayu,
        name: "Гайворонская Мария Георгиевна",
        speciality: "Врач кардиолог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["26 лет"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: [
                    "Волгоградский государственный медицинский институт",
                ],
            },
            {
                header: "Образование:",
                description: [
                    "Волгоградский государственный медицинский институт",
                ],
            },
            {
                header: "Повышение квалификации:",
                description: [
                    "2015 г. – повышение квалификации по программе «Клиническая кардиология» (ВолГМУ)",
                    "2015 г. – Сертификат специалиста по специальности «Кардиология» (ВолГМУ)",
                ],
            },
            {
                header: "Список заболеваний, которые диагностирует врач:",
                description: [
                    "ССС - гипертонической болезни; ИБС; нарушений ритма сердца и ПС; гипертония; гипотония; сердечная недостаточность; воспаления сердца: эндокардит, миокардит, перикардит; ишемическая болезнь сердца.",
                ],
            
            },
        ],
    },
    {
        image: grigorieva,
        name: "Григорьева Лариса Вячеславовна",
        speciality: "Врач-хирург",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["29 лет"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: [
                    "- Волгоградский медицинский институт (Специальность: «Лечебное дело (1988г.);",
                    "- Интернатура по специальности: «Хирургия» (1989г.);",
                    "- Ординатура по специальности «Хирургия» (1994г.)"
                ],
            },
            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "- Повышение квалификации по программе: «Неотложная хирургия органов брюшной полости» в ВолГМУ (144 ч.) (2011 г.);",
                    "- Повышение квалификации по программе: «Хирургия» в ВолГМУ (144ч.) (2016 г.);",
                    "- Повышение квалификации по программе: «Плазмолифтинг» 2017 г.",
                    "- Сертификат специалиста по специальности: «Хирургия» (2016 г.)"
                ],
            },

        ],
    },
    {
        image: isaeva,
        name: "Исаева Наталья Станиславовна",
        speciality: "Стаж работы по врачебной специальности: 16 лет. Квалификационная категория: нет. Ученая степень: нет",
        doctorInfo: [
            {
                header: "ВУЗ:",
                description: [
                    "- Волгоградская медицинская академия (Специальность: «Лечебное дело» (1998г.); ",
                    "- Интернатура по специальности: «Терапия» (1999г.);",
                    "- Клиническая ординатура по специальности: «Терапия» (2001 г.)",
                    "- Волгоградский медицинский университет"
                ],
            },
            {
                header: "Профессиональные навыки и применяемые методы лечения и диагностики в клинике «Эксперт»:",
                description: [
                    "Сбор жалоб, анамнеза, объективных данных пациента с патологией органов ЖКТ, интерпретация лабораторных и инструментальных данных, назначение медикаментозной терапии.",
                    
                ],
            },
            {
                header: "Список заболеваний, которые лечит врач:",
                description: [
                    "Заболевание органов ЖКТ",
                    "- Профессиональная переподготовка по программе: «Первичная специализация по гастроэнтерологии» (2005 г.);",
                    "- Повышение квалификации по программе: «Терапия с актуальными вопросами гастроэнтерологии» (2015 г.)",
                    "- Сертификат специалиста по специальности: «Гастроэнтерология» (от 30.04.2015 г.)"
                ],
            },

        ],
    },
    {
        image: kuznetsovael,
        name: "Кузнецова Елена Львовна",
        speciality: "Врач онколог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["29 лет (13 лет по специальности «онкология»)"],
            },
            {
                header: "Квалификационная категория:",
                description: ["высшая категория"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: [
                    "- Волгоградский государственный медицинский университет (2004 г.), специальность: онкология",
                   
                ],
            },
            {
                header: "Образование:",
                description: [
                    "- 1988 г. - Волгоградский медицинский институт;",
                    "- 1989 г. - Интернатура, Больница Водников г. Ростов по специальности «Терапия»",

                ],
            },
            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "- 2014 г. - Сертификат специалиста по специальности «Онкология» (ВолГМУ)",
                    "- 2018 г. - повышение квалификации по программе «Современные методы диагностики и лечения злокачественных опухолей» (ВолГМУ)",

                ],
            },
            {
                header: "Список заболеваний, которые диагностирует и лечит врач:",
                description: [
                    "Лечение, диагностика и профилактические мероприятия образований злокачественного или доброкачественного характера.",
                    

                ],
            },
        ],
    },
    {
        image: letunovskaya,
        name: "Летуновская Ольга Викторовна",
        speciality: "Врач  - гинеколог, врач ультразвуковой диагностики",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["Более 15 лет."],
            },
            {
                header: "Информация:",
                description: ["Имеет диплом об окончании Волгоградской государственной медицинской академии по специальности ''Лечебное дело''. Окончила интернатуру по акушерству и гинекологии. Имеет сертификаты по акушерству и гинекологии и ультразвуковой диагностике. Регулярно повышает квалификацию по актуальным вопросам в своей специализации.Ольга Викторовна занимается лечением и профилактикой гинекологических заболеваний, проводит такие манипуляции и диагностику, как видеокольпоскопия, биопсия, эхогистероскопия, лазеролечение. Доктор также проводит и различные ультразвуковые исследования: органов малого таза, фолликулогенез. Имеет огромный опыт успешного лечения таких заболеваний как миома, эндометриоз, бесплодие, киста, патологии яичников."],
            },
           
        ],
    },
    {
        image: litvinenko,
        name: "Литвиненко Мадина Геннадьевна",
        speciality: "Врач ультразвуковой диагностики",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["26 лет"],
            },
            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "«Волгоградская медицинская академия», Т.У. по физиологии и патологии детей старшего возраста (1999 г.);",
                    "«Волгоградская медицинская академия», Т.У. по физиологии и патологии детей старшего возраста (1999 г.);",
                    "«Волгоградская медицинская академия», Т.У. по физиологии и патологии детей старшего возраста (1999 г.);",
                    "«ФГУ ЦИТО им.Н. Н. Пирогова», ультразвуковоая диагностика заболеваний и повреждений крупных суставов (2011 г.);",
                    "«ФГБОУ ДПО «ИПК ФМ-БА», основы пренатального скрининга в 11-14 недель беременности (2017 г.);",
                    "«ФГБОУВО «ВГМУ», ультразвуковоая диагностика в гинекологии (2018 г.);",
                    "«ФГБОУВО «ВГМУ», ультразвуковоая диагностика заболеваний брахиоцефальных сосудов (2019 г.);",
                    "«ФГБОУВО «ВГМУ», ультразвуковоая маммография (2020 г.).",
                ],
            },
            {
                header: "Профессиональные навыки и применяемые методы диагностики в клинике «Эксперт»:",
                description: [
                    "УЗИ органов брошной полости, молочные железы, щитовидная железа, органы малого таза, мягкие ткани, предстательная железа, мочевой пузырь, лимфатические узлы.",
                   
                ],
            },
           
        ],
    },
    {
        image: matrosov,
        name: "Матросов Владимир Геннадьевич",
        speciality: "Врач-колопроктолог, врач-хирург",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["18 лет по специальности «Колопроктология»"],
            },
            {
                header: "Квалификационная категория:",
                description: ["Высшая"],
            },
            {
                header: "ВУЗ:",
                description: ["Волгоградский государственный медицинский институт (Специальность: «Лечебное дело» (1986г.)"],
            },
            {
                header: "Список заболеваний, которые лечит врач:",
                description: [
                    "- Геморрой;",
                    "- Механические повреждения, воспалительные и инфекционные заболевания толстого кишечника и анального отверстия (язвы, колит, парапроктит и т. д.);",
                    "- Эпизодические или хронические запоры, недержание кала, поносы;",
                    "- Новообразования, локализующиеся в толстом кишечнике, прямой кишке и близлежащих тканях – полипы, кисты, раковые и доброкачественные опухоли;",
                    "- Выпадение прямой кишки; врожденные аномалии кишечника, ректальной области и близлежащих тканей.",
                ],
            
            },
            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "- Повышение квалификации по программе: «Колопроктология» в ВолГМУ (144 ч.) (2016 г.)",
                    "- Повышение квалификации по программе: «Хирургия» (144 ч.) (2018 г.)",
                    "- Сертификат специалиста по специальности: «Колопроктология» (2016 г.)",
                    "- Сертификат специалиста по специальности: «Хирургия» (2018 г.)",
                ],
            },
        ],
    },

    {
        image: melnik,
        name: "Мельникова Ольга Андреевна",
        speciality: "Врач-дераматовенеролог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["6 лет."],
            },
            {
                header: "ВУЗ:",
                description: [
                    "- Волгоградский государственный медицинский университет (Специальность: «Лечебное дело» (2011г.);",
                    "- Интернатура по специальности: «Дерматовенерология» (2013г.);"
                ],
            },
            {
                header: "Список заболеваний, которые лечит врач:",
                description: [
                    "псориаз;себорейный дерматит;аллергодерматоз разных видов – аллергический, контактный, экзема, нейродермит, крапивница, дерматит;плоский красный и розовый лишай;герпес, бородавки, ВПЧ;родинки, папилломы;акне, постакне;гонорея;трихомониаз;остроконечные кондиломы;генитальный герпес;урогенитальный кандидоз, хламидиоз;контагиозный моллюск;разновидности грибка ногтей, кожи (цветной лишай, онихомикоз).Проводит процедуры плазмолифтинга",
                    
                ],
            },  
            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "- Повышение квалификации по программе: «Дерматовенерология» в ВолГМУ(144ч.) (2018г.);",
                    "- Мастер-класс по программе: «Plasmolifting Geltherapy в косметологии» (08 ч.) (2017 г.);",
                    "- Мастер-класс по программе: «Plasmolifting в косметологии и трихологии» (08 ч.) (2017 г.)",
                    "- Сертификат специалиста по специальности: «Дерматовенерология» (2018 г.)",
                ],
            },    
        ],
    },
    {
        image: nazarova,
        name: "Назарова Нина Владимировна",
        speciality: "Врач аллерголог-иммунолог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["36 лет"],
            },
            {
                header: "Квалификационная категория:",
                description: [
                    "Высшая квалификационная категория по специальности «Аллергология и иммунология» (2013 г.)",
                    "Высшая квалификационная категория по специальности «Педиатрия» (2013 г.)"
                ],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: ["Волгоградский государственный медицинский институт"],
            },
            {
                header: "Образование:",
                description: ["Волгоградский государственный медицинский институт (1981 г.)"],
            },
            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "2018 г. – повышение квалификации по программе: «Избранные вопросы аллергологии и иммунологии» (клиническая аллергология, клиническая иммунология, неотложные состояния в аллергологии и иммунологии) (ФГБО УВО «ВолГМУ»)",
                    "2018 г. – Сертификат специалиста по специальности «Аллергология и иммунология» (ФГБО УВО «ВолГМУ»)",
                ],
            },
            {
                header: "Список заболеваний, которые диагностирует и лечит врач:",
                description: ["Аллергический ринит, бронхиальная астма, атопический дерматит, крапивница, паразитозы."],
            },
           
        ],
    },
    {
        image: nazaruk,
        name: "Назарук Александр Сергеевич",
        speciality: "Врач хирург, врач сосудистый хирург",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["34 года"],
            },
            {
                header: "Квалификационная категория:",
                description: ["высшая"],
            },
            {
                header: "Образование:",
                description: [
                    "Окончил Волгоградский государственный медицинский институт в 1984 г.",
                    "1998 г. – Сертификат по специальности «Хирургия» (Волгоградская медицинская академия);",
                    "2003г. и 2013г. – подтверждение Сертификата по специальности «Хирургия» (Волгоградский государственный медицинский университет);",
                ],
            },
            {
                header: "Профессиональные навыки и применяемые методы лечения и диагностики в клинике «Эксперт»:",
                description: [
                    "После службы в армии (ВДВ) в 1978-1984 гг. учился на лечебном факультете Волгоградской медицинской академии. После окончания вуза работал хирургом и главным врачом в Верхне-Балыклейской участковой больнице Дубовского района Волгоградской области. С 1987 г. работа связана с ГУЗ КБСМП № 15, сначала в качестве клинического ординатора кафедры хирургических болезней ФУВ, а затем в качестве врача хирургического отделения. С 1993 г. и по настоящее время работает в должности ассистента кафедры хирургических болезней и нейрохирургии ФУВ ВолГМУ.",
                ],
            },
            {
                header: "Список заболеваний, которые диагностирует и лечит врач:",
                description: [
                    "Атеросклероз нижних конечностей, тромбоз почечных артерий; диабетические ангиопатии; флебит, тромбоз и тромбофлебит; варикозное расширение вен.",
                ],
            },
            
        ],
    },
    {
        image: naumenko,
        name: "Науменко Антон Евгеньевич",
        speciality: "Врач - уролог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["3 года"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: ["Волгоградский государственный медицинский университет"],
            },
            {
                header: "Образование:",
                description: [
                    "2014 г. - Волгоградский государственный медицинский университет",
                    "2016 г. - Ординатура по специальности «Урология» (Волгоградский государственный медицинский университет)",
                ],
            },
            {
                header: "Повышение квалификации, переквалификация:",
                description: ["2016 г. – Сертификат специалиста по специальности «Урология» (Волгоградский государственный медицинский университет)"],
            },
            {
                header: "Список заболеваний, которые диагностирует и лечит врач:",
                description: ["Воспалительные, инфекционные заболевания: острый и хронический циститы; пиелонефриты; острый и хронический простатиты; неспецифические уретриты - ИППП; мочекаменная болезнь; аденома предстательной железы."],
            },
           
        ],
    },
    {
        image: noName,
        name: "Николаева Светлана Петровна",
        speciality: "Врач - онколог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["28 лет"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: ["Волгоградский медицинский институт"],
            },
            {
                header: "Образование:",
                description: ["Волгоградский медицинский институт"],
            },
            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "2014 г. – Повышение квалификации по программе: «Современные методы диагностики и лечения злокачественных опухолей» (144 ч.) (ГБОУ ВПО «ВолГМУ»)",
                    "2014 г. – Сертификат специалиста по специальности «Онкология» (ГБОУ ВПО «ВолГМУ»)",
                ],
            },
            {
                header: "Список заболеваний, которые диагностирует и лечит врач:",
                description: ["Лечение, диагностика и профилактические мероприятия образований злокачественного или доброкачественного характера."],
            },
           
        ],
    },
    {
        image: noginaNV,
        name: "Ногина Наталья Вячеславовна",
        speciality: "Врач - невролог",
        doctorInfo: [
            {
                header: "Образование:",
                description: [
                    "2001 г. - окончание Волгоградской медицинской академии по специальности «Лечебное дело».",
                    "2002 г. - окончание интернатуры по специальности «Неврология» на базе ММУ «Больница №16», г. Волгоград.",
                    "2013 г. - профессиональная переподготовка по специальности «ЛФК и спортивная медицина» на кафедре медицинской реабилитации и спортивной медицины с курсом медицинской реабилитации, лечебной физкультуры, спортивной медицины, физиотерапии ФУВ ВолгГМУ.",
               
                ],
            },
            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "2013 г. - повышение квалификации по программе «Актуальные вопросы профпатологии» на кафедре ОВП с профпатологией ФУВ ВолгГМУ.",
                    "2014 г. - повышение квалификации по программе «Локальная терапия боли» на курсе неврологии, мануальной терапии, рефлексотерапии ФУВ ВолгГМУ.",
                    "2014 г. - повышение квалификации по программе «Формирование здорового образа жизни» на кафедре гигиены ФУВ ВолгГМУ.",
                    "2016 г. - повышение квалификации по программе «Нейрореабилитация при цереброваскулярной патологии» на курсе неврологии, мануальной терапии, рефлексотерапии ФУВ ВолгГМУ.",
                    "2018 г. - сертификационный цикл по специальности «Неврология», ЧУДПО «ИПиПКСЗ», г. Новосибирск.",
                    "2018 г. - повышение квалификации по программе «Экспертиза временной нетрудоспособности», ЧУДПО «ИПиПКСЗ», г. Новосибирск.",
                ],
            },
            
           
        ],
    },
    {
        image: oreshina,
        name: "Орешина Елена Юрьевна",
        speciality: "Врач - кардиолог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: [
                    "32 года",
                ],
            },
            {
                header: "ВУЗ:",
                description: ["Волгоградский государственный медицинский институт (1986 г.)"],
            },
            {
                header: "Интернатура:",
                description: ["Черновицкий медицинский институт по специальности «Терапия» (1987 г.)"],
            },
            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "— Повышение квалификации по программе: «Кардиология» в ЧУДПО «Институте переподготовки и повышения квалификации специалистов здравоохранения» (2020 г.);",
                    "— Повышение квалификации по программе: «Экспертиза временной нетрудоспособности» в ООО ДПО «Университет профессиональных стандартов» (2020 г.);",
                    "— Повышение квалификации по программе: «Экспертиза качества медицинской помощи» в ООО ДПО «Университет профессиональных стандартов» (2020 г.);",
                    "— Сертификат специалиста по специальности «Кардиология» в ЧУДПО «Институт переподготовки и повышения квалификации специалистов здравоохранения» (апрель 2020 г.);",
                    "— Повышение квалификации по программе «Кардиология» в ЧУ ДПО «ИПиПКСЗ» (2020 г.);",
                    "— Сертификат специалиста по специальности «Кардиология» в ЧУ ДПО «ИПиПКСЗ» (апрель 2020 г.);",
                    "— Повышение квалификации по программе «Функциональная диагностика в кардиологии» в ВолгГМУ (2019 г.);",
                    "— Сертификат специалиста по специальности «Функциональная диагностика» в ВолгГМУ (декабрь 2019 г.);",
                    "— Повышение квалификации по программе: «Актуальные вопросы общественного здоровья. Государственная программа развития здравоохранения» в ВолгГМУ (2017 г.);",
                    "— Сертификат специалиста по специальности «Организация здравоохранения и общественное здоровье» в ВолгГМУ (июнь 2017 г.);",
                    "— Повышение квалификации по программе: «Экспертиза временной и стойкой нетрудоспособности. Экспертиза качества медицинской помощи» в ВолгГМУ (2015 г.);",
                    "— Повышение квалификации по программе: «Порядок организации и проведения контроля объемов, сроков, качества и условий предоставления медицинской помощи по ОМС в ВолгГМУ (2015 г.);",
                    "— Повышение квалификации по программе: «Клиническая кардиология» в ВолгГМУ (2015 г.);",
                    "— Подготовка по теме: «Порядок организации контроля объемов, сроков, качества и условий предоставления медицинской помощи по ОМС» (2015 г.);",
                    "— Профессиональная переподготовка по программе: «Общественное здоровье и здравоохранение» в ВолгГМУ (2012 г.);",
                    "— Повышение квалификации по программе: «Избранные вопросы кардиологии» в ВолгГМУ (2011 г.);",
                    "— Повышение квалификации по программе: «Экспертиза временной и стойкой нетрудоспособности; экспертизе качества медицинской помощи» (2010 г.);",
                    "— Повышение квалификации по программе: «Актуальные проблемы диагностики и лечения артериальной гипертензии» в ВМА (2001 г.).",
                ],
            },
            {
                header: "Профессиональные навыки и применяемые методы лечения и диагностики в клинике «Эксперт»:",
                description: [
                "— Холтеровское мониторирование, суточное мониторирование АД, ЭКГ;",
                "— Назначает исследование: ЭхоКГ, ДСБЦА, сосудов почек, щитовидной железы; лабораторные методы.",
                
                ],
            },
            {
                header: "Список заболеваний, которые лечит врач:",
                description: [
                "Гипертоническая болезнь, нарушение сердечного ритма, стенокардия, миокардит, перекардит, эндокардит, пороки сердца, «боли в области сердца».",

                ],
            },
            
           
        ],
    },
    {
        image: ostryanina,
        name: "Острянина Елена Валерьевна",
        speciality: "Врач - кардиолог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["33 года"],
            },
            {
                header: "Квалификационная категория:",
                description: ["высшая"],
            },
            {
                header: "ВУЗ:",
                description: ["ВГМИ"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: ["ВГМУ, проходила подготовку в клинической ординатуре с 1985 по 1989 год."],
            },
            {
                header: "Профессиональные навыки и применяемые методы лечения и диагностики в клинике «Эксперт»:",
                description: ["Расшифровка ЭКГ, постановка и чтение суточного мониторирования ЭКГ, интерпретация полученных данных, в соответствии с которыми проводится коррекция лечения."],
            },
            {
                header: "Список заболеваний, которые диагностирует и лечит врач:",
                description: ["лечение ИБС: стенокардия напряжения стабильная, нестабильная; -гипертоническая болезнь, все стадии; нарушение сердечного ритма; экстренная помощь при гипертоническом кризе, болях в сердце ( с последующей госпитализацией)."],
            },

        ],
    },
    {
        image: pavlova,
        name: "Павлова Анна Эдуардовна",
        speciality: "Врач ультразвуковой диагностики",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["16 лет"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: ["Российская медицинская академия последипломного образования"],
            },
            {
                header: "Образование:",
                description: [
                    "Волгоградская медицинская академия (2000 г.)",
                    "Интернатура по специальности «Фтизиопульмонология» на кафедре Фтизиопульмонологии (Волгоградская медицинская академия)",
                ],
            },
            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "2001 г. – профессиональная переподготовка по программе «Ультразвуковая диагностика» (Российская медицинская академия последипломного образования)",
                    "2006 г. - повышение квалификации по программе: «Ультразвуковая диагностика заболеваний органов брюшной полости, малого таза и поверхностно расположенных органов» (ФГУ «Учебно-научный медицинский центр» УД Президента РФ»)",
                    "2008 г. – повышение квалификации по программе: «Ультразвуковая диагностика» (ГОУ ДПО «Российская медицинская академия последипломного образования»)",
                    "2011 г. – повышение квалификации по программе: «Ультразвуковая диагностика» (ГОУВПО СПб ГМА им. И.И. Мечникова)",
                    "2016 г. - повышение квалификации по программе: «Актуальные вопросы ультразвуковой диагностики по международным стандартам» (ООО «ЭКСПРОМЕД»)",
                    "2016 г. – Сертификат специалиста по специальности «Ультразвуковая диагностика» (ООО «ЭКСПРОМЕД»)",
                ],
            },
            {
                header: "Информация:",
                description: ["Ведет прием взрослых и детей с рождения. Проводит ультразвуковую диагностику внутренних и поверхностных органов, сосудов верхних и нижних конечностей, сердца, шеи, головного мозга, глазных орбит и пр."],
            },
         

        ],
    },
    {
        image: petruhina,
        name: "Петрухина Галина Геннадиевна",
        speciality: "Врач оториноларинголог первой категории",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["с 1996 г."],
            },
            {
                header: "Образование:",
                description: [
                    "1989 -1995 гг.Волгоградская медицинская академия, педиатрический факультет, по специальности «Педиатрия»",
                    "1995 – 1996 гг. Волгоградская медицинская академия, на кафедре оториноларингологии, ординатура по оториноларингологии.",
                    "1998 г. – Лазерный центр ММУ ФТП г.Волгоград, специализация лазеротерапия",
                    "1999г,2004г .2009г.,2014г. Курсы тематического усовершенствования на кафедре оториноларингологии на базе Волгоградского медицинского университета.",
                    "2006 г. – Российская Медицинская Академия Последипломного Образования (РМАПО),г. Москва.обучение по «Ямик- терапии» на базе кафедры оториноларингологии",
                    "Владеет беспункционным методом лечения гайморита – ямик катетер, а также в совершенстве владеет техниками промыванием лакун миндалин, вливания в гортань, вскрытия и дренирования абцессов, лазеротерапией.",
                ],
            },
            {
                header: "Научная деятельность:",
                description: [
                    "Член Российского общества оториноларингологов.",
                    "Участница конгрессов, съездов и конференций по оториноларингологии в г. Волгоград, г. Москва, г. Санкт- Петербург, г. Казань, г. Краснодар.",
                    "Имеет успешный опыт лечения таких патологий как аденоидит, синусит, ринит (инфекционный, аллергический, гормональный, медикаментозный), фарингит, тонзиллит, ларингит, отит, серная пробка, искривление перегородки носа, травмы носа, попадание в лор-органы инородных тел, гипертрофия аденоидов и небных миндалин.",
                ],
            },
         

        ],
    },
    {
        image: savushkin,
        name: "Савушкин Дмитрий Викторович",
        speciality: "Специалист по массажу",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["25 лет"],
            },
            {
                header: "Образование:",
                description: ["Волгоградский мед. колледж №2"],
            },
            {
                header: "Профессиональные навыки и применяемые методы лечения и диагностики в клинике «Эксперт»:",
                description: ["классический, сегментарный, точечный, висцеральный, гуаша, шиатсу, старорусский."],
            },
            {
                header: "Список заболеваний, которые диагностирует и лечит врач:",
                description: ["Люмбаго, остеохондроз, ИШИАС, радикулит, корешковый синдром и др.заболевания опорно-двигательного аппарата, лечение заболеваний брюшной полости, бронхо - легочные заболевания и др."],
            },

         

        ],
    },
    {
        image: noName,
        name: "Симонова Марина Геннадьевна",
        speciality: "Врач функциональной диагностики",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["26 лет"],
            },
            {
                header: "Квалификационная категория:",
                description: ["Высшая"],
            },
            {
                header: "ВУЗ:",
                description: ["Волгоградский медицинский институт (1994 г.)"],
            },
            {
                header: "Интернатура:",
                description: ["Волгоградская медицинская академия (1995 г.)"],
            },
            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "— Профессиональная переподготовка: цикл специализации «Функциональная диагностика» на кафедре Клинической физиологии и функциональной диагностики РМАПО;",
                    "— Повышении квалификации по программе «Функциональна диагностика» в ВолгГМУ (2015 г.);",
                    "— Повышении квалификации по программе «Функциональна диагностика» в ВолгГМУ (2020 г.).",
                ],
            },
            {
                header: "Профессиональные навыки и применяемые методы лечения и диагностики в клинике «Эксперт»:",
                description: ["Врач функциональной диагностики"],
            },
         

        ],
    },
    {
        image: subbotina,
        name: "Субботина Людмила Николаевна",
        speciality: "Врач - терапевт, врач - кардиолог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["23 года"],
            },
            {
                header: "ВУЗ:",
                description: ["Волгоградская медицинская академия (24.06.1995 г.)"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: ["ВМА - терапия, ВолГМУ - кардиология."],
            },

            {
                header: "Образование и повышение квалификации::",
                description: [
                    "2005 г. ВолГМУ – усовершенствование ТУ по вопросам терапии с актуальными проблемами по клинической фармакологии в клинике внутренних болезней.",
                    "2010 г. ВолГМУ – повышение квалификации по клинической кардиологии: нарушение ритма сердца, ИБС, артериальная гипертония, др. заболевания сердца и сосудов.",
                    "2011 г. Кафедра кардиологии ФУВ ВолГМУ – диплом о профессиональной переподготовке по специальности «Кардиология».",
                    "2013 г. Кафедра кардиологии ФУВ ВолГМУ – усовершенствование: методы обследования и лечения ИБС, ИМ, гипертоническая болезнь, болезнь миокарда, перикарда, эндокарда, ПС, нарушение ритма сердца, неотложная кардиология.",
                    "2015 г. Кафедра терапии и эндокринологии ФУВ ВолГМУ – «Актуальные вопросы терапии» (пульмонология, кардиология, гастроэнтерология, болезни эндокринной системы, болезни нарушения обмена).",
                    "2017 г. – повышение квалификации, кафедра кардиологии с курсом ФД и ФУВ по программе «Клиническая электрокардиография» (клиническая электрокардиография, ИБС, ИМ, артериальная гипертензия, НРС и ПС, недостаточность кровообращения).",
                ],
            },
            {
                header: "Научная деятельность:",
                description: ["Принимает активное участие в работе профильных конференций, семинаров, форумов. Постоянно повышает свои профессиональные знания."],
            },
            {
                header: "Список заболеваний, которые диагностирует и лечит врач:",
                description: ["Диагностика, лечение и профилактика заболеваний внутренних органов, верхних и нижних дыхательных путей, ЖКТ, мочеполовой системы, заболеваний суставов и соединительной ткани, эндокринных заболеваний и нарушений обмена, ССС - гипертонической болезни, ИБС, нарушений ритма сердца и ПС."],
            },

        ],
    },
    {
        image: tvorogova,
        name: "Творогова Лариса Георгиевна",
        speciality: "Врач - терапевт",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["16 лет"],
            },
            {
                header: "Квалификационная категория:",
                description: ["Первая категория. Имеет диплом об окончании Волгоградского государственного меди-цинского института по специальности Лечебное дело. 1993-1999гг. Окончила интернатуру по терапии в 2000г. Имеет сертификаты по те-рапии и врача общей практики. Регулярно повышает квалификацию по актуальным вопросам в своей специализации."],
            },
            {
                header: "Список заболеваний, которые диагностирует и лечит врач:",
                description: ["Ведет прием как врач-терапевт. Проводит диагностику, лечение и про-филактику заболеваний внутренних органов: желудочно-кишечного тракта, сердечно-сосудистой системы, мочевыделительной системы, за-болевания суставов, простудные заболевания (ОРВИ, фарингиты, тра-хеиты, бронхиты) и другое."],
            },
           
        ],
    },
    {
        image: titovskaya,
        name: "Титовская Надежда Александровна",
        speciality: "Врач - кардиолог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["21 год"],
            },
            {
                header: "Образование:",
                description: [
                    "ВУЗ: Волгоградская медицинская академия (1999 г.)",
                    "Интернатура: Волгоградская медицинская академия по специальности «Терапия» (2000 г.)",
                ]    
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации по специальности «Кардиология»:",
                description: ["Волгоградская медицинская академия (2000 г.)"],
            },
            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "– Повышение квалификации по программе «Функциональная диагностика в кардиологии» в ВолгГМУ (2019 г.);",
                    "– Сертификат специалиста по специальности «Функциональная диагностика» в ВолгГМУ (декабрь 2019 г.);",
                    "Повышение квалификации по программе «Кардиология» в ЧУ ДПО «ИПиПКСЗ» (2020 г.);",
                    "Сертификат специалиста по специальности «Кардиология» в ЧУ ДПО «ИПиПКСЗ» (апрель 2020 г.).",
                ]    
            },
            {
                header: "Профессиональные навыки и применяемые методы лечения и диагностики в клинике «Эксперт»:",
                description: ["Консультативный кардиологический прием, расшифровка ЭКГ и суточных мониторов ЭКГ и СМАД, расшифровка спирометрии."],
            },
            {
                header: "Список заболеваний, которые лечит врач:",
                description: ["Гипертоническая болезнь, нарушение сердечного ритма, симтоматические гипертензии и т.д."],
            },
           
        ],
    },
    {
        image: tolkunova,
        name: "Толкунова Ирина Валерьевна",
        speciality: "Врач терапевт-гастроэнтеролог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["более 23 лет"],
            },
            {
                header: "Сведения об образовании:",
                description: [
                    "Имеет диплом об окончании Волгоградского государственного медицинского института по специальности Лечебное дело. Окончила интернатуру и клиническую ординатуру по терапии. Имеет сертификаты по терапии и гастроэнтерологии. Регулярно повышает квалификацию по актуальным вопросам в своей специализации.",
                ]    
            },

            {
                header: "Список заболеваний, которые лечит врач:",
                description: ["Ведет прием как врач-терапевт и как врач-гастроэнтеролог, проводит диагностику, лечение и профилактику заболеваний внутренних органов и желудочно-кишечного тракта."],
            },
           
        ],
    },
    {
        image: filippova,
        name: "Филиппова Юлия Борисовна",
        speciality: "Врач - эндокринолог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["26 лет"],
            },
            {
                header: "ВУЗ:",
                description: [
                    "Московский ордена Ленина государственный медицинский институт им. Н.И. Пирогова по специальности «Лечебное дело» (1994 г.)",
                ]    
            },

            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: ["Клиническая ординатура: Российский государственный медицинский университет по специальности «Эндокринология» (1996г.)"],
            },

            {
                header: "Повышение квалификации, переквалификация:",
                description: [
                    "— Повышение квалификации по программе «Эндокринология» в ФГБОУ ДПО «Российская медицинская академия непрерывного профессионального образования» Министерства здравоохранения РФ г. Москва (2017 г.);",
                    "— Сертификат специалиста по специальности «Эндокринология» в ФГБОУ ДПО «Российская медицинская академия непрерывного профессионального образования» Министерства здравоохранения РФ г. Москва (2017 г.).",
                ],
            },

            {
                header: "Профессиональные навыки и применяемые методы лечения и диагностики в клинике «Эксперт»:",
                description: ["Врач эндокринолог"],
            },
           
            
            {
                header: "Список заболеваний, которые лечит врач:",
                description: ["Сахарный диабет; болезни щитовидной железы; дефицит или избыток кальция; болезни, связанные с нарушением обмена веществ."],
            },
        ],
    },

    {
        image: chupryna,
        name: "Чупрына Петр Сергеевич",
        speciality: "Врач - уролог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["22 года (в 2008 г. присвоена высшая кв. категория)"],
            },
            {
                header: "ВУЗ, где выдан документ о первичной специализации:",
                description: [
                    "— Волгоградская государственная медицинская академия, специальность «лечебное дело» (1998 г.);",
                    "— Волгоградская медицинская академия (клиническая ординатура), специальность «хирургия» (1999 г).",
                ]    
            },

            {
                header: "Повышение квалификации:",
                description: ["— Ростовский государственный медицинский университет, специальность «урология» (2000 г)."],
            },

            {
                header: "Профессиональная переподготовка:",
                description: [
                    "— «ФГБОУВО «ВГМУ», диплом — ультразвуковая диагностика (2017 г.);",
                    "— «ФГБОУВО «ВГМУ», сертификат специалиста — ультразвуковая диагностика (2017 г.).",
                ],
            },

            {
                header: "Профессиональные навыки и применяемые методы лечения и диагностики в клинике «Эксперт»:",
                description: ["— УЗИ органов урогенитального тракта."],
            },
           
            
            {
                header: "Список заболеваний, которые лечит врач:",
                description: ["Воспалительные заболевания верхних и нижних МВП. Лечение заболеваний почек, мочеточников, простаты и мочевого пузыря. Мужское бесплодие."],
            },
        ],
    },

    {
        image: shaposhnikova,
        name: "Шапошникова Наталья Владимировна",
        speciality: "Врач - невролог",
        doctorInfo: [
            {
                header: "Стаж работы по врачебной специальности:",
                description: ["Стаж работы 20 лет."],
            },
            {
                header: "Информация:",
                description: [
                    "Специалист с большим опытом, диагностирует и лечит заболевания, вызванные поражением центральной и периферической нервной системы, а так же функциональные нарушения у детей.",
                    "Наталья Владимировна эффективно решает такие распространённые проблемы как нарушение сна, задержка моторного развития, заикание, головные боли, повышенная возбудимость и пр.Доктор разрабатывает индивидуальную для каждого случая программу лечения, контролирует процесс, даёт ценные рекомендации по профилактике заболеваний.",
                ]    
            },

         
        ],
    },
];
