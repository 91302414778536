export const priceInfoPsychologist = [
    {
        code: "12.1",
        name: "Приём клинического психолога",
        price: "1050",
    },
    {
        code: "12.2",
        name: "Клинико-психологическая диагностика",
        price: "1200",
    }
];
