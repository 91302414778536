export const priceInfoCardiologist = [
    {
        code: "5.1",
        name: "Прием первичный врача кардиолога",
        price: "950",
    },
    {
        code: "5.2",
        name: "Прием повторный врача кардиолога",
        price: "900",
    },
    {
        code: "5.3",
        name: "Консультация врача кардиолога для коррекции лечения",
        price: "650",
    }
];
