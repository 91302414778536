export const priceInfoOtorhinolaryngologist = [
    {
        code: "10.1",
        name: "Прием врача-оториноларинголога первичный ( взрослый\детский)",
        price: "1000",
    },
    {
        code: "10.2",
        name: "Прием врача-оториноларинголога повторный ( взрослый \ детский)",
        price: "850",
    },
    {
        code: "10.3",
        name: "Консультация врача оториноларинголога для коррекции лечения",
        price: "650",
    },
    {
        code: "10.4",
        name: "Прием врача-оториноларинголога ( взрослый\детский) с видео эндоскопическим обследованием",
        price: "1500",
    },
    {
        code: "10.5",
        name: "Дренирование фурункула наружного уха",
        price: "650",
    },
    {
        code: "10.6",
        name: "Введение лекарственных препаратов в гортань",
        price: "550",
    },
    {
        code: "10.7",
        name: "Обработка слизистой глотки",
        price: "450",
    },
    {
        code: "10.8",
        name: "Промывание лакун миндалин и глотки",
        price: "650",
    },
    {
        code: "10.9",
        name: " Механическая остановка носового кровотечения",
        price: "700",
    },
    {
        code: "10.10",
        name: " Пневмо \ вибромассаж барабанных перепонок",
        price: "400",
    },
    {
        code: "10.11",
        name: "Продувание слуховой трубы ( 1 сторона, 1 процедура)",
        price: "450",
    },
    {
        code: "10.12",
        name: "Промывание верхнечелюстных пазух носа",
        price: "500",
    },
    {
        code: "10.13",
        name: "Уход за наружным слуховым проходом",
        price: "400",
    },
    {
        code: "10.14",
        name: "Удаление инородного тела из глотки, уха , носа",
        price: "750",
    },
    {
        code: "10.15",
        name: "Удаление ушной серы ( 1 сторона )",
        price: "550",
    },
    {
        code: "10.16",
        name: "Введение лекарственных препаратов интраназально на турунде",
        price: "300",
    },
    {
        code: "10.17",
        name: "Введение лекарственных препаратов в наружный слуховой проход на турунде",
        price: "350",
    },
    {
        code: "10.18",
        name: "Введение лекарственных препаратов интраназально с целью лечения рецидивов носовых кровотечений",
        price: "650",
    },
    {
        code: "10.19",
        name: "Заушные блокады с лекарственными препаратами",
        price: "350",
    },
    {
        code: "10.20",
        name: "Инъекционное введение лекарственных препаратов внутрираковинно",
        price: "500",
    },
    {
        code: "10.21",
        name: "Промывание верхнечелюстных пазух носа с помощью катетера Ямик",
        price: "2750",
    },
    {
        code: "10.22",
        name: "Вестибулометрия",
        price: "400",
    },
    {
        code: "10.23",
        name: "Вскрытия паратонзиллярного абсцесса",
        price: "1000",
    },
    {
        code: "10.24",
        name: "Вскрытие фурункула носа",
        price: "1100",
    },
    {
        code: "10.25",
        name: "Дренирование абсцесса",
        price: "650",
    },
    {
        code: "10.26",
        name: "Введение лекарственных препаратов в барабанную полость через наружный слуховой проход",
        price: "550",
    },
    {
        code: "10.27",
        name: "Введение лекарственных препаратов интраназально",
        price: "500",
    },
    {
        code: "10.28",
        name: "Введение лекарственных препаратов в наружный слуховой проход",
        price: "550",
    },
    {
        code: "10.29",
        name: "ОТОРИНОЛАРИНГОЛОГ Схема №1",
        price: "",
    },
    {
        code: "",
        name: "Схема № 1 Промывание носоглотки антисептическим препаратом (5 раз)",
        price: "2650",
    },
    {
        code: "",
        name: "Схема № 1 Динамический осмотр врача-оториноларинголога после 5 процедуры",
        price: "450",
    },
    {
        code: "",
        name: "Схема № 1 Повторный осмотр врача-оториноларинголога по результатам лечения",
        price: "600",
    },
    {
        code: "",
        name: "Схема № 3 ПЕРВИЧНАЯ консультация врача-оториноларинголога",
        price: "700",
    },
    {
        code: "10.30",
        name: "ОТОРИНОЛАРИНГОЛОГ Схема №2",
        price: "",
    },
    {
        code: "",
        name: "Схема № 2 ПЕРВИЧНАЯ консультация врача-оториноларинголога",
        price: "700",
    },
    {
        code: "",
        name: "Схема № 2  Туалет носа (анемизация слизистой и санация носоглотки с  электроотсосом) №5",
        price: "1550",
    },
    {
        code: "",
        name: "Схема № 2 Динамический осмотр врача-оториноларинголога после 5 процедуры",
        price: "450",
    },
    {
        code: "",
        name: "Схема № 2 Повторная консультация  врача-оториноларинголога по результатам лечения",
        price: "600",
    },
    {
        code: "10.31",
        name: "ОТОРИНОЛАРИНГОЛОГ Схема № 3",
        price: "",
    },
    {
        code: "",
        name: "Схема № 3 ПЕРВИЧНАЯ консультация врача-оториноларинголога",
        price: "700",
    },
    {
        code: "",
        name: "Схема № 3 Промывание лакун миндалин инструментальное - №5",
        price: "1900",
    },
    {
        code: "",
        name: "Схема № 3 Обработка лекарственными препаратми № 5",
        price: "2200",
    },
    {
        code: "",
        name: "Схема № 3 Повторная консультация  врача-оториноларинголога по результатам лечения",
        price: "600",
    },
    {
        code: "10.32",
        name: "ОТОРИНОЛАРИНГОЛОГ Схема №4",
        price: "",
    },
    {
        code: "",
        name: "Схема № 4 ПЕРВИЧНАЯ консультация врача-оториноларинголога",
        price: "700",
    },
    {
        code: "",
        name: "Схема № 4 Промывание лакун миндалин инструментальное - №5",
        price: "1800",
    },
    {
        code: "",
        name: "Схема № 4 Обработка лекарственными препаратми № 5",
        price: "1800",
    },
    {
        code: "",
        name: "Схема № 4 Повторная консультация  врача-оториноларинголога по результатам лечения",
        price: "600",
    },
    {
        code: "10.33",
        name: "ОТОРИНОЛАРИНГОЛОГ Тонзилор",
        price: "",
    },{
        code: "10.34",
        name: 'УЗ лечение  хронич. тонзилита -"Тонзилор"',
        price: "950",
    },{
        code: "10.35",
        name: 'УЗ лечение  хронич. фарингита -"Тонзилор"',
        price: "600",
    },{
        code: "10.36",
        name: 'УЗ лечение  хронич. острого отита -"Тонзилор"',
        price: "600",
    },{
        code: "10.37",
        name: 'Фонофорез миндалин с лекарственным препаратом -"Тонзилор"',
        price: "600",
    },
];
