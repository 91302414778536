export const priceInfoTreatmentRoom = [
    {
        code: "11.1",
        name: "Инъекция внутримышечная, внутрикожная",
        price: "130",
    },
    {
        code: "11.2",
        name: "Пульсоксиметрия",
        price: "60",
    },
    {
        code: "11.3",
        name: "Время свёртывания",
        price: "130",
    },
    {
        code: "11.4",
        name: "	Инъекция внутривенная",
        price: "200",
    },
    {
        code: "11.6",
        name: "Лечебная плазмотерапия (плазмолифтинг), 1 пробирка",
        price: "2400",
    },
    {
        code: "11.7",
        name: "Лечебная плазмотерапия (плазмолифтинг), 2 пробирки",
        price: "2900",
    },
    {
        code: "11.8",
        name: "Аутогемотерапия",
        price: "200",
    },
    {
        code: "11.9",
        name: "Инфузия внутривенная ( капельница )",
        price: "500",
    },
    {
        code: "11.10",
        name: "Дополнительное введение препарата",
        price: "50",
    },
    {
        code: "11.11",
        name: "Измерение артериального давления",
        price: "100",
    },
    {
        code: "11.12",
        name: "Забор биоматериала ( кровь, мазок) на лабораторное исследование",
        price: "130",
    },
    {
        code: "11.13",
        name: "Измерение уровня сахара в крови ( глюкотест)",
        price: "150",
    },
    {
        code: "11.14",
        name: "Тест на определение наличия бактерии Хеликобактер пилори",
        price: "650",
    },
    {
        code: "11.15",
        name: "Тест на определение ацетона в моче",
        price: "150",
    },
    {
        code: "11.16",
        name: "Тест на определение глюкозы в моче",
        price: "150",
    },
    {
        code: "11.17",
        name: "Вызов медсестры на дом (в пределах Красноармейского района)",
        price: "900",
    },
    {
        code: "11.18",
        name: "Наклейка пластыря",
        price: "10",
    }
];
