export const priceInfoCardiovascularSurgeon = [
    {
        code: "15.1",
        name: "Первичный прием врача сердечно-сосудистого хирурга",
        price: "1000",
    },
    {
        code: "15.2",
        name: "Повторный прием врача сердечно-сосудистого хирурга",
        price: "900",
    }
    ,
    {
        code: "15.2",
        name: "Консультация врача сердечно-сосудистого хирурга для коррекции лечения",
        price: "600",
    }
];
