export const priceInfoGastroenterologist = [
    {
        code: "3.1",
        name: "Прием первичный врача -  гастроэнтеролога",
        price: "1000",
    },
    {
        code: "3.2",
        name: "Прием повторный врача -  гастроэнтеролога",
        price: "900",
    },
    {
        code: "3.3",
        name: "Консультация врача гастроэнтеролога для коррекции лечения",
        price: "600",
    },
];
