import React from "react";
import { InformationContainerComponent } from "../../../components/InformationContainerComponent/InformationContainerComponent";
import logo from "../../../img/icon.png";
export const InfoOffDocumentsPage = () => {
    return (
        <div className="InfoOffDocumentsPage">
            <InformationContainerComponent header="Официальные документы">
                <div className="documentsContainer">  
                    <img className="logo" src={logo} alt="" />
                    <a href="https://drive.google.com/file/d/1L3pXTZcerpMdX204pVcEK1SZYjd0ysy3/view?usp=sharing">Правила внутреннего распорядка для пациентов ООО "Квалифицированная медицинская помощь"</a>
                
              
                    <br></br><br></br>
                    <img className="logo" src={logo} alt="" />
                    <a href="https://drive.google.com/file/d/1nMLQEAL5bQEaG3ISUbncV1VXYlwoDhzI/view?usp=sharing">Федеральный закон "Об основах охраны здоровья граждан в РФ"</a>
                    <br></br><br></br>
                    <img className="logo" src={logo} alt="" />
                    <a href="https://drive.google.com/file/d/1J0MZAKJr73x3md1m8KHy6l0HYAghTa0Q/view?usp=sharing">Постановление Правительства РФ от 04.10.2012 N 1006 </a>
                    <br></br><br></br>
                    <img className="logo" src={logo} alt="" />
                    <a href="https://drive.google.com/file/d/1Eaim1G8T7RjpISGG5Gpwxeu0afHR-jso/view?usp=sharing">Права и обязанности  граждан в сфере охраны здоровья</a>
                    <br></br><br></br>
                    <img className="logo" src={logo} alt="" />
                    <a href="https://drive.google.com/file/d/1yRBe7Me2hfWgZmfNWRaGUs9RhtzDietH/view?usp=sharing">Перечень жизненно необходимых и важнейших лекарственных препаратов (ЖНВЛП)</a>
                    <br></br><br></br>
                    <img className="logo" src={logo} alt="" />
                    <a href="https://drive.google.com/file/d/1yRBe7Me2hfWgZmfNWRaGUs9RhtzDietH/view?usp=sharing">Перечень жизненно необходимых и важнейших лекарственных препаратов (ЖНВЛП)</a>
                    <br></br><br></br>
                    <img className="logo" src={logo} alt="" />
                    <a href="https://drive.google.com/file/d/1yRBe7Me2hfWgZmfNWRaGUs9RhtzDietH/view?usp=sharing">Перечень жизненно необходимых и важнейших лекарственных препаратов (ЖНВЛП)</a>
                    <br></br><br></br>
                    <img className="logo" src={logo} alt="" />
                    <a href="https://drive.google.com/file/d/1nMLQEAL5bQEaG3ISUbncV1VXYlwoDhzI/view?usp=sharing">Сводная ведомость результатов проведения специальной оценки условий труда</a>
                    <br></br><br></br>
                    <img className="logo" src={logo} alt="" />
                    <a href="https://docs.google.com/document/d/1kdEbzSrYUW2WHFopFJ1hZ7VKAxvgNGpW/edit?usp=sharing&ouid=112631188905352530754&rtpof=true&sd=true">Постановление Правительства РФ от 4 отктября 2012 года № 1006 "Об утверждении Правил предоставления медицинскими организациями медицинских услуг</a>
                    <br></br><br></br>
                    <img className="logo" src={logo} alt="" />
                    <a href="https://drive.google.com/file/d/1DTO9eHz1iGeOLJNx1s_k3l6qRKqaMZuS/view?usp=sharing">Закон Волгоградской области №127-ОД от 06.12.2019 "О территориальной программе государственных гарантий бесплатного оказания гражданам медицинской помощи в Волгоградской области на 2020 год и на плановый период 2021 и 2022 годов</a>
                    <br></br><br></br>
                    <img className="logo" src={logo} alt="" />
                    <a href="https://docs.google.com/document/d/19XMNpTEeQq5JAfPilZur3KZDKrSWHTaP/edit?usp=sharing&ouid=112631188905352530754&rtpof=true&sd=true">Перечень лекарственных препаратов, назначаемых по решению врачебных комиссий </a>
                    <br></br><br></br>
                    <a href="https://docs.google.com/document/d/1rmfx8Ws3SycPfpUamYGEx4loyCT_H8mU/edit?usp=sharing&ouid=112631188905352530754&rtpof=true&sd=true">Перечень лекарственных препаратов, предназначенных для лечения лиц, больных гемофилией</a>
                    <br></br><br></br>
                    <img className="logo" src={logo} alt="" />
                    <a href="https://docs.google.com/document/d/1zIKCMGYTXFtHEkP27yabdIpF_6Rx8SrS/edit?usp=sharing&ouid=112631188905352530754&rtpof=true&sd=true">Приложение №1 к Постановлению Правительства от 30 июля 1994г. №8901</a>
                    <br></br><br></br>
                    <img className="logo" src={logo} alt="" />
                    <a href="https://docs.google.com/document/d/1-FyBycf-XITPu1ShaJiD2uo1kZil8tg5/edit?usp=sharing&ouid=112631188905352530754&rtpof=true&sd=true">Федеральный закон от 27.07.2006 №1520-ФЗ (реж. от 31.12.2011)</a>
                    <br></br><br></br>
                </div>
            </InformationContainerComponent>
        </div>
    );
};
