export const comments = [
  {
    name: "Пациент",
    description:
      "Наблюдаюсь у гинеколога Летуновской Ольги Викторовны Врач от Бога!! Очень благодарна , за ее профессионализм, лечение помогает, сама проводит обследование ,все очень доступно объясняет, очень внимательная. Лечилась у многих гинекологов в Красноармейском районе ,на сегодняшний момент это лучший доктор!!",
  },
  {
    name: "Ольга Шульженко",
    description:
      "Хочу поблагодарить Бережнову Надежду за очень хорошее и вежливое обращение с пациентами. Очень редкое сочетание – симпатичная девушка, профессионализм.Если нужно качество и внимательность при снятие кардиограммы обращайтесь в клинику ЭКСПЕРТ к Надежде Бережновой.",
  },
  {
    name: "Пациент",
    description:
      "Наблюдаемся у лор врача Петрухиной Г.Г. Очень грамотный доктор, увидела ,то,что в детской поликлинике долгое время не замечали, назначила лечение, сразу помогло. Мы очень довольны результатом. Спасибо, доктор!!",
  },
  {
    name: "Петрова О. Л.",
    description:
      "Хочу поблагодарить Анну Эдуардовну за очень хорошее и вежливое обращение с пациентами. Очень редкое сочетание – симпатичная девушка, профессионализм и уважение к старшим. Она провела мне ультразвуковую диагностику желчного пузыря и, не смотря на не утешительный диагноз, помогла добрым словом и дала необходимые рекомендации. Кстати, после очень сложной операции, позже, мы случайно встретились в коридоре, и она поздоровалась со мной по имени и отчеству, пожелала скорейшего выздоровления. А это говорит о том, что Анна Эдуардовна с душой относится к своей работе и переживает за своих пациентов. Вот сейчас дочка подсказала, что у Анны Эдуардовны фамилия то, Павлова. Спасибо ей большое.",
  },
  {
    name: "Пациент",
    description:
      "Спасибо большое доктору Богдашину А.Л. Много раз обращалась за помощью к разным врачам, а помог реально только он. Золотые руки, светлый ум, больше бы таких специалистов!!!",
  },
  {
    name: "Златкова Е. С.",
    description:
      "Здравствуйте. Хочу от чистого сердца поблагодарить Галину Геннадиевну Петрухину. Этот человек помог мне избавиться от постоянных головных болей, а особенно от болей в области носа, при низком положении головы. У меня небольшой огород и я просто не могла его обрабатывать, при любом наклоне -  испытывала невыразимую боль. Я знала, что у меня правосторонний, хронический гайморит уже 10 лет, но когда обратилась в обычную больницу, мне рекомендовали какое-то пункционное лечение. После консультации у Галины Геннадьевны, я решилась на курс лечения в этой клинике и сейчас об этом не жалею. Теперь я свободно могу пропалывать свои грядки, мыть полы руками, а не длинной шваброй, и у меня нет этих страшных головных болей. Спасибо вашей клинике и отдельное спасибо Галине Геннадиевне Петрухиной!",
  },
  {
    name: "Пациент",
    description:
      "18 февраля побывала первый раз в Клинике Эксперт плюс. В целом, очень довольна. В регистратуре очень вежливый внимательный персонал, врач Узи обходительная,общительная, рассказала куда обратиться дальше с моей проблемой, успокоила. Очень человечное отношение! Спасибо!",
  },
  {
    name: "Пациент",
    description:
      "Очень хорошая клиника! Все специалисты опытные, грамотные. Спасибо медсестре Летуновской Вере за доброту и профессионализм!",
  },
  {
    name: "Алексеева Н. Г.",
    description:
      "Имею честь поблагодарить Кин Ирину Александровну за сердечное и чуткое отношение к больным. Редко, когда ты узнаешь о своей болезни – тебе помогают простые люди, пусть и белых халатах. Ирина Александровна не только на все 100% правильно провела ультразвуковую диагностику, с постановкой предварительного диагноза (потом это подтвердилось при оперативном вмешательстве), но и поддержала в трудную минуту, добрым словом и хорошим советом. И вообще, в этой клинике очень вежливый и профессиональный персонал. Я не помню такого отношения к больным уже лет 20 или 30, когда распался Советский Союз.",
  },
  {
    name: "Еремин Г. В.",
    description:
      "Кто желает решить проблему в очень интимной и сокровенной области – обращайтесь к Летуновской Ольге Викторовне. Я не специалист по написанию текстов, особенно благодарностей, но Ольга Викторовна помогла нам стать счастливой семьей, в которой теперь есть ребенок, дочь. Пишу благодарность не только от своего имени, но и от имени своих родителей, которые наконец-то стали бабушками и дедушками. Ольга Викторовна проявила высокий профессионализм и неповторимую чуткость в диагностировании и лечении болезни моей жены, спасибо ей огромное!",
  },
  {
    name: "Пациент",
    description:
      'Побывала в клинике "Эксперт плюс". Общее впечатление отличное. Очень грамотные врачи, особенно Павлова А.Э. Спасибо большое за душевное отношение и помощь. Вообще весь персонал очень вежливый, девушки в регистратуре просто замечательные. Всем советую эту клинику.',
  },
];
