export const priceInfoTraumatologistOrthopedist = [
    {
        code: "17.1",
        name: "Прием врача травматолога-ортопеда первичный",
        price: "1000",
    },
    {
        code: "17.2",
        name: "Прием врача травматолога-ортопеда повторный",
        price: "900",
    },
    {
        code: "17.3",
        name: "Консультация врача ортопеда травматолога",
        price: "650",
    },
    {
        code: "17.4",
        name: "Пункция коленного сустава с введением гиалурон к-ты(с предварительной блокадой,препарат пациента)",
        price: "1500",
    },
    {
        code: "17.5",
        name: "Внутрисуставное ведение препарата",
        price: "800",
    },
    {
        code: "17.6",
        name: "Снятие гипсовой повязки",
        price: "400",
    },
];
