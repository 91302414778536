import React from "react";
import { InformationContainerComponent } from "../../../components/InformationContainerComponent/InformationContainerComponent";
import "./InfoRegulationsPage.css"
import {AboutClinicComponent} from "../../../components/AboutClinicComponent/AboutClinicComponent"
import {ServicesComponent} from "../../../components/ServicesComponent/ServicesComponent"

export const InfoRegulationsPage = () => {
    return (
        <div className="InfoRegulationsPage">
            <InformationContainerComponent header="Положение о предоставлении платных медицинских услуг">
                <div className="servisesContainer">

                    <h3 className="servisesHeading">  Глава 1. ОБЩИЕ ПОЛОЖЕНИЯ </h3>
                   
                    <div className="servisesText">  1.1. Правила внутреннего распорядка для пациентов - это  регламент  (порядок)  
                    выполнения профессиональной деятельности сотрудниками клиники, обеспечивающий  получение  пациентом  
                    медицинской помощи надлежащего качества.  </div>

                    <div className="servisesText"> 1.2. Правила внутреннего распорядка медицинской клиники «ЭКСПЕРТ»  
                    ООО «КМП» (далее – «Клиника») обязательны для пациентов, проходящих обследование и лечение в данной  
                    клинике, их законных представителей, посетителей и являются основой 
                    в отношениях между Пациентом и Клиникой. </div>
                    

                    <div className="servisesText"> 1.3. Правила внутреннего распорядка для пациентов включают:</div>

                    <div className="servisesText"> 1.3.1. Порядок обращения пациента в клинику;</div>

                    <div className="servisesText"> 1.3.2. Правила пребывания в клинике пациентов и родственников;</div>

                    <div className="servisesText"> 1.3.3. Права и обязанности пациента;</div>

                    <div className="servisesText"> 1.3.4. Режим работы клиники;</div>

                    <div className="servisesText"> 1.3.5. Порядок разрешения конфликтных ситуаций между клиникой и пациентом;</div>
                    
                    <div className="servisesText"> 1.3.6. Порядок предоставления информации о состоянии здоровья пациента;</div>
                    
                    <div className="servisesText"> 1.4. При несоблюдении пациентом предписаний или настоящих правил внутреннего распорядка, 
                    лечащий врач может отказаться по согласованию с администрацией клиники от наблюдения и лечения пациента, если это не 
                    угрожает жизни пациента и здоровью окружающих.</div>

                    <h3 className="servisesHeading">  Глава 2. ПОРЯДОК ОБРАЩЕНИЯ ПАЦИЕНТА В КЛИНИКУ </h3>

                    <div className="servisesText"> 2.1. Каждый  гражданин  Российской Федерации   имеет  право получить  
                    медицинскую помощь в частном медицинском учреждении  здравоохранения 
                    по своему выбору  за  счет  собственных  средств  или  средств  
                    физических или юридических лиц.</div>

                    <div className="servisesText">2.2. При    состояниях,    требующих    срочного     
                    медицинского вмешательства    (несчастный  случай,  травма,  отравление,   
                    другие состояния  и  заболевания,  угрожающие жизни или здоровью 
                    гражданина или  окружающих  его  лиц),  необходимо  обратиться в 
                    государственную службу скорой медицинской помощи по телефону (03).</div>

                    <div className="servisesText"> 2.3. Для предварительной записи на прием к 
                    врачу пациент обращается в регистратуру клиники при непосредственном 
                    обращении, либо по телефону.</div>

                    <div className="servisesText">2.4. При первичном  обращении на  пациента  заводится  медицинская  карта амбулаторного  больного,  в  которую  вносятся  
                    следующие сведения о пациенте:
                    <br></br>
                        - фамилия, имя, отчество (полностью);
                    <br></br>
                        - пол;
                    <br></br>
                        - дата рождения (число, месяц, год);
                    <br></br>
                        - адрес по данным прописки (регистрации) на основании документов, удостоверяющих личность              (паспорт, регистрационное  свидетельство);
                    <br></br>
                        - серия и номер паспорта;
                    <br></br>
                        - номер регистрационного свидетельства (для иностранцев);
                    <br></br>
                    - реквизиты удостоверения беженца (для беженцев). </div>

                    <div className="servisesText"> 2.5. Пациент может получить в  регистратуре следующую информацию: </div>
                    
                    <div className="servisesText"> 2.5.1. Время приема врачей всех специальностей во все 
                    дни недели с указанием часов приема и номеров кабинетов; </div>

                    <div className="servisesText"> 2.5.2. Порядок предварительной записи на прием к врачам; </div>
                    
                    <div className="servisesText"> 2.5.3. Время и место приема главным врачом;  </div>

                    <div className="servisesText"> 2.5.4. Адрес  ближайших и дежурных аптек, поликлиник и стационаров,  
                    оказывающих  экстренную  врачебную  помощь в вечернее, ночное время, в воскресные и праздничные дни. </div>

                    <div className="servisesText"> 2.6. Направление  на  госпитализацию  пациентов,  
                    нуждающихся  в плановом стационарном  лечении,  
                    осуществляется   амбулаторно-поликлиническим учреждением  после    предварительного 
                    обследования. Направление выдается в определенную больницу с указанием предварительного диагноза.</div>
                    
                    <h3 className="servisesHeading">  ПЕРВИЧНЫМ ПРИЕМОМ врача любой специальности считается: </h3>
                    
                    <div className="servisesText">
                    - первое обращение в клинику;
                    <br></br><br></br>
                    - обращение после перерыва в 3 месяца и больше;
                    <br></br><br></br>
                    - обращение по другому заболеванию, независимо от сроков обращения. </div>

                    <h3 className="servisesHeading">  ПОВТОРНЫМ (последующим) ПРИЕМОМ  считается: </h3>
                    <div className="servisesText"> 
                    - обращение по одному и тому же диагнозу в течении 3-х месяцев;
                    <br></br><br></br>
                    - обращение для получения направлений для контрольного обследования;
                    <br></br><br></br>
                    - обращение за получением рекомендаций после первичного приема и обследования. </div>      
                
                    <h3 className="servisesHeading">  Глава 3. ПРАВИЛА ПРЕБЫВАНИЯ В УЧРЕЖДЕНИИ ПАЦИЕНТОВ И РОДСТВЕННИКОВ </h3>
                    
                    <div className="servisesText">3.1. При первичном посещении клиники необходимо прийти за 15-20 минут до назначенного 
                    времени для оформления амбулаторной карты и договора на предоставление медицинских услуг, 
                    ри повторных посещениях за 10-15 минут.
                    <br></br><br></br>
                    При каждом обращении в клинику необходимо предъявлять документ удостоверяющий 
                    ичность (для взрослого человека - паспорт, для ребенка до 14 лет - свидетельство о 
                    рождении и паспорт представителя ребенка). При обращении ребенка с родственниками либо  представителями 
                    ребенка  необходимо иметь нотариально заверенную доверенность от родителей или законных представителей ребенка.
                    <br></br><br></br>
                    В случае опоздания на прием (по предварительной записи) более чем на 5 минут, 
                    врачу дано право начать прием следующего по очереди пациента. Опоздавший пациент будет принят, 
                    когда у врача появится свободное время для приема.
                    <br></br><br></br>
                    Учитывая сложности обследования и тяжесть заболевания некоторых 
                    пациентов, прием может задерживаться на  5-10 минут. </div>

                    <div className="servisesText">3.2. Снять верхнюю одежду, поместив её в индивидуальный шкаф гардероба. </div>

                    <div className="servisesText">3.3. В гардеробе не рекомендуется оставлять ценные вещи, 
                    поскольку в случае их пропажи администрация клиники ответственности не несет. </div>

                    <div className="servisesText">3.4. Получить в регистратуре амбулаторную карту и бахилы. 
                    Пройти в кабинет. Одевать бахилы необходимо непосредственно перед кабинетом врача. </div>

                    <div className="servisesText">3.5. Входить в кабинет врача с пациентом может только один из законных представителей.. </div>

                    <div className="servisesText">3.6. Соблюдать чистоту и порядок в кабинетах, коридорах, туалетах; </div>

                    <div className="servisesText">3.7. Бережно относиться к имуществу клиники. В случае причинения ущерба любому 
                    виду имущества (уничтожение, порча, повреждение и т.д.) виновное лицо, родители или родственники пациента 
                    обязаны возместить все убытки. </div>

                    <div className="servisesText">3.8. Пациентам и родственникам запрещается: </div>

                    <div className="servisesText">3.8.1. нарушать тишину и покой в клинике; </div>

                    <div className="servisesText">3.8.2. пользоваться сотовым телефоном на территории клиники ; </div>

                    <div className="servisesText">3.8.3. курить и распивать спиртные напитки на территории и в помещениях клиники. </div>
                    
                    <h3 className="servisesHeading">  Глава 4. ПРАВА И ОБЯЗАННОСТИ ПАЦИЕНТА </h3>

                    <div className="servisesText">4.1. При обращении за медицинской помощью и ее получении пациент имеет право на: </div>
                    
                    <div className="servisesText">4.1.1. уважительное и гуманное отношение со стороны работников 
                    и других лиц, участвующих в оказании медицинской помощи; </div>

                    <div className="servisesText">4.1.2. получение информации о фамилии, имени, отчестве, должности и квалификации его лечащего 
                    врача и других лиц, непосредственно участвующих в оказании 
                    ему медицинской помощи; </div>

                    <div className="servisesText">4.1.3. обследование, лечение и содержание в условиях, 
                    соответствующих санитарно-гигиеническим и противоэпидемическим 
                    ребованиям; </div>

                    <div className="servisesText">4.1.4. перевод к другому лечащему врачу с 
                    разрешения главного врача клиники; </div>

                    <div className="servisesText">4.1.5. добровольное согласие информированного пациента на 
                    медицинское вмешательство в соответствии с законодательными актами. </div>

                    <div className="servisesText">4.1.6. отказ от оказания (прекращения) медицинской помощи, 
                    за исключением случаев, предусмотренных законодательными актами; </div>

                    <div className="servisesText">4.1.7. обращение с жалобой к должностным лицам учреждения, 
                    а также к должностным лицам вышестоящей организации или в суд; </div>

                    <div className="servisesText">4.1.8. сохранение медицинскими работниками в тайне информации 
                    о факте его обращения за медицинской помощью, состоянии здоровья, диагнозе и иных сведений, 
                    полученных при его обследовании и лечении, за исключением случаев, предусмотренных 
                    законодательными актами; </div>

                    <div className="servisesText">4.1.9. получение в доступной для него форме полной информации 
                    о состоянии своего здоровья, применяемых методах диагностики и лечения, 
                    а также на выбор лиц, которым может быть передана информация 
                    о состоянии его здоровья; </div>

                    <div className="servisesText">4.1.10. Подачу в письменном виде 
                    своих предложений по совершенствованию деятельности клиники; </div>
                    
                    <div className="servisesText">4.2. Пациенты  обязаны: </div>

                    <div className="servisesText">4.2.1. Неукоснительно выполнять назначения лечащего врача, 
                    рекомендации и просьбы медицинского персонала; </div>

                    <div className="servisesText">4.2.2. Принимать меры к сохранению и укреплению своего здоровья; </div>

                    <div className="servisesText">4.2.3. Своевременно обращаться за медицинской помощью; </div>

                    <div className="servisesText">4.2.4. Уважительно относиться к медицинским работникам и другим лицам,
                     участвующим в оказании медицинской помощи; </div>

                    <div className="servisesText">4.2.5. Представлять  лицу,  оказывающему медицинскую помощь, 
                    известную ему  достоверную информацию о состоянии своего здоровья, в том числе о  
                    противопоказаниях  к  применению лекарственных  средств,   
                    ранее перенесенных и наследственных заболеваниях; </div>
                    
                    <div className="servisesText">4.2.6. Сотрудничать  с  врачом  на  всех  этапах  
                    оказания медицинской помощи; </div>

                    <div className="servisesText">4.2.7. Соблюдать    правила    внутреннего    распорядка  для  пациентов;  </div>

                    <div className="servisesText">4.2.8. Соблюдать чистоту и порядок в кабинетах, коридорах, туалетах; </div>

                    <div className="servisesText">4.2.9. Бережно относиться к имуществу клиники.     </div>

                    <h3 className="servisesHeading">  Глава 5. РЕЖИМ РАБОТЫ КЛИНИКИ </h3>

                    <div className="servisesText">
                        Понедельник:       с 8.00 до 20.00 <br></br><br></br>
                        Вторник:           с 7.00 до 20.00<br></br><br></br>
                        Среда:             с 8.00 до 20.00 <br></br><br></br>
                        Четверг:           с 8.00 до 20.00<br></br><br></br>
                        Пятница:           с 8.00 до 20.00<br></br><br></br>
                        Суббота:           с 8.00 до 18.00<br></br><br></br>
                        Воскресенье:       с 9.00 до 16.00
                    </div>
                    <div className="servisesText">5.2. График работы врачей необходимо уточнять 
                    в регистратуре клиники по телефонам: 50-88-77, 50-87-87. </div>

                    <div className="servisesText">5.3. Время проведения медицинских процедур осуществляется 
                    согласно назначениям лечащего врача и режиму работы клиники. </div>

                    <h3 className="servisesHeading">  Глава 6. ПОРЯДОК РАЗРЕШЕНИЯ КОНФЛИКТНЫХ СИТУАЦИЙ </h3>

                    <div className="servisesText">6.1. В случае нарушения прав пациента, он (его законный представитель) 
                    может обращаться с жалобой непосредственно к  главному врачу клиники. </div>
                

                    <div className="servisesText">6.2. В спорных случаях пациент имеет право обращаться в вышестоящую организацию:
                    отдел по защите прав потребителей центрального района г. Волгограда;
                    Федеральную службу по надзору в сфере здравоохранения и социального развития;
                    Министерство по здравоохранению администрации Волгоградской области. </div>

                    <h3 className="servisesHeading">  Глава 7. ПРЕДОСТАВЛЕНИЕ СВЕДЕНИЙ </h3>

                    <div className="servisesText">7.1. Сведения о состоянии здоровья пациентов предоставляются лечащими 
                    врачами только пациенту или законным представителям в личной беседе 
                    (ст.13, ст.22 «Основ законодательства об охране здоровья граждан Российской Федерации»). </div>
                
                    <div className="servisesText">7.2. Законными представителями несовершеннолетнего являются родители, 
                    усыновители, опекуны, попечители, представители учреждений, на попечении которых находится 
                    несовершеннолетний  (Семейный кодекс РФ, ст.56,п.1; Федеральный закон «Об опеке и попечительстве» №48-ФЗ от 24.04.2008). </div>

                    <div className="servisesText">7.3. Сведения о состоянии здоровья пациента другим лицам предоставляются 
                    только с письменного разрешения пациента или его законного представителя.</div>
                
                
                </div>
            </InformationContainerComponent>
        </div>
    );
};
