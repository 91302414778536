export const priceInfoPulmonologist = [
    {
        code: "13.1",
        name: "Первичный прием врача пульмонолога",
        price: "1000",
    },
    {
        code: "13.2",
        name: "Повторный прием врача пульмонолога",
        price: "900",
    }
    ,
    {
        code: "13.2",
        name: "	Консультация врача пульманолога",
        price: "600",
    }
];
