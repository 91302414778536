export const priceInfoAllergist = [
    {
        code: "1.1",
        name: "Первичный приём врача аллерголога-иммунолога",
        price: "900",
    },
    {
        code: "1.2",
        name: "Повторный приём врача аллерголога-иммунолога",
        price: "750",
    },
    {
        code: "1.3",
        name: "Первичный приём врача аллерголога-иммунолога с назначением начального курса сублингвальной АСИТ",
        price: "10588",
    },
    {
        code: "1.4",
        name: "Консультация врача аллерголога-иммунолога для коррекции лечения",
        price: "600",
    },
    {
        code: "1.5",
        name: "Заочное консультирование по результатам обследования",
        price: "500",
    },
    {
        code: "1.6",
        name: "Аутосеротерапия (курс лечения-10 дней)",
        price: "6050",
    },
    {
        code: "1.7",
        name: "Аутосеротерапия 1 процедура",
        price: "600",
    },
];
