export const priceInfoOncologist = [
    {
        code: "9.1",
        name: "Первичный прием врача онколога",
        price: "1000",
    },
    {
        code: "9.2",
        name: "Повторный прием врача онколога",
        price: "900",
    },
    {
        code: "9.3",
        name: "Консультация врача онколога для коррекции лечения",
        price: "650",
    },
    {
        code: "9.4",
        name: "Хирургическая обработка послеоперационной раны (онкология)",
        price: "750",
    },
    {
        code: "9.5",
        name: "Хирургическая обработка послеоперационной раны со снятием послеоперационных швов",
        price: "750",
    },
    {
        code: "9.6",
        name: "Хирургическая обработка глубокой раны (онкология)",
        price: "1500",
    },
    {
        code: "9.7",
        name: "Наложение асептической повязки малой",
        price: "400",
    },
    {
        code: "9.8",
        name: "Наложение асептической повязки большой",
        price: "500",
    },
    {
        code: "9.9",
        name: "Перевязка /наложение мазевых повязок",
        price: "500",
    },
    {
        code: "9.10",
        name: "Радиохирургическая удаление новообразования 1 категории сложности",
        price: "1700",
    },
    {
        code: "9.11",
        name: "Радиохирургическое удаление новообразований 2 категории сложности",
        price: "2300",
    },
    {
        code: "9.12",
        name: "Радиохирургическое удаление новообразований 3 категории сложности",
        price: "3150",
    },
    {
        code: "9.13",
        name: "Инфильтрационная анестезия",
        price: "650",
    },
    {
        code: "9.14",
        name: "Пункция мягких тканей с эвакуацией лимфы",
        price: "1000",
    },
    {
        code: "9.15",
        name: "Криодеструкция новообразований",
        price: "4300",
    },
    {
        code: "9.16",
        name: "Взятие мазка - отпечатка с поверхности опухоли",
        price: "400",
    },
    {
        code: "9.17",
        name: "Взятие мазка отделяемого из соска",
        price: "400",
    },
    {
        code: "9.18",
        name: "Взяти соскоба с поверхности новообразования кожи",
        price: "450",
    },
    {
        code: "9.19",
        name: "Тонкоигольная биопсия опухоли мягких тканей, лимфатического узла",
        price: "1000",
    },
    {
        code: "9.20",
        name: "Тонкоигольная биопсия опухоли молочной железы",
        price: "1100",
    },
    {
        code: "9.21",
        name: "Тонкоигольная биопсия опухоли щитовидной железы",
        price: "1100",
    },
    {
        code: "9.22",
        name: "Тонкоигольная биопсия опухоли под УЗИ контролем",
        price: "2200",
    },
];
