import MainSliderPic1 from "./img/Frame_134.png";
import MainSliderPic2 from "./img/Frame_133.png";
import MainSliderPic3 from "./img/Frame_135.png";
import MainSliderPic4 from "./img/Frame_136.png";
export const pictures = [
  {
    url: MainSliderPic1,
  },
  {
    url: MainSliderPic2,
  },
  {
    url: MainSliderPic3,
  },
  {
    url: MainSliderPic4,
  },
];
