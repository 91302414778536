export const priceInfoPhysiotherapy = [
    {
        code: "20.1",
        name: "Прием врача физиотерапевта лечебный, первичный",
        price: "500",
    },
    {
        code: "20.2",
        name: "Прием врача физиотерапевта лечебный, повторный",
        price: "200",
    },
    {
        code: "20.3",
        name: "	Консультация врача физиотерапевта",
        price: "200",
    },
    {
        code: "20.4",
        name: "Электрофорез 1 поле (без стоимости препаратов)",
        price: "275",
    },
    {
        code: "20.5",
        name: "Электрофорез 2 поля (без стоимости препаратов)",
        price: "330",
    },
    {
        code: "20.6",
        name: "Электрофорез лекарственных препаратов 1 поле",
        price: "330",
    },
    {
        code: "20.7",
        name: "Микроволновая резонансная КВЧ терапия (пунктура)",
        price: "264",
    },
    {
        code: "20.8",
        name: "Ультразвуковая терапия (1 поле)",
        price: "264",
    },
    {
        code: "20.9",
        name: "Ультразвуковая терапия (2 поля)",
        price: "330",
    },
    {
        code: "20.10",
        name: "Фонофорез (1 поле)",
        price: "275",
    },
    {
        code: "20.11",
        name: "Фонофорез (2 поля)",
        price: "374",
    },
    {
        code: "20.12",
        name: "Магнитная терапия (1 поле)",
        price: "209",
    },
    {
        code: "20.14",
        name: "Терапия ПерМП  (1 поле)",
        price: "209",
    },
    {
        code: "20.15",
        name: "Магнитная терапия (2 поля)",
        price: "275",
    },
    {
        code: "20.16",
        name: "Магнитная терапия (3 поля)",
        price: "330",
    },
    {
        code: "20.17",
        name: "Лазерная терапия (1 поле)",
        price: "220",
    },
    {
        code: "20.18",
        name: "Лазерная терапия (2 поля)",
        price: "264",
    },
    {
        code: "20.19",
        name: "Лазерная терапия НИЛИ (2 области, 2 сустава)",
        price: "308",
    },
    {
        code: "20.20",
        name: "Лазерная терапия (3 поля)",
        price: "363",
    },
    {
        code: "20.21",
        name: "Лазерная терапия КЛО и ИК ЛО",
        price: "303",
    },
    {
        code: "20.22",
        name: "Лазерная терапия, с использованием матричного  излучателя ИК НИЛИ (1 область/1 сустав)",
        price: "275",
    },
    {
        code: "20.23",
        name: "Лазерная терапия, с использованием матричного  излучателя ИК НИЛИ (2 области/2 сустава)",
        price: "363",
    },
    {
        code: "20.24",
        name: "Лазерная терапия НЛОК (лазерная обработка крови)",
        price: "308",
    },
    {
        code: "20.25",
        name: "Синусоидально-модулированные токи 1 поле (СМТ, амплипульс)",
        price: "242",
    },
    {
        code: "20.26",
        name: "Синусоидально-модулированные токи 2 поля",
        price: "330",
    },
    {
        code: "20.27",
        name: "ДДТ (диадинамик) 1 поле",
        price: "242",
    },
    {
        code: "20.28",
        name: "ДДТ (диадинамические токи) 2 поля",
        price: "330",
    },
    {
        code: "20.29",
        name: "Флюктурирующие токи 1 поле",
        price: "253",
    },
    {
        code: "20.30",
        name: "Флюктурирующие токи 2 поля",
        price: "330",
    },
    {
        code: "20.31",
        name: "Чрескожная электроаналгезия",
        price: "242",
    },
    {
        code: "20.32",
        name: "Электронейромиостимуляция",
        price: "297",
    },
    {
        code: "20.33",
        name: "Транскраниальная электротерапия",
        price: "297",
    },
    {
        code: "20.34",
        name: "Транскраниальная амплипульстерапия",
        price: "297",
    },
    {
        code: "20.35",
        name: "Центральная электроаналгезия",
        price: "297",
    },
    {
        code: "20.36",
        name: "Электросон",
        price: "297",
    },
    {
        code: "20.37",
        name: "Физиотерапевтическое лечение 1 процедура (1 кат)",
        price: "242",
    },
    {
        code: "20.38",
        name: "Физиотерапевтическое лечение 1 процедура (2 кат)",
        price: "275",
    },
    {
        code: "20.39",
        name: "Физиотерапевтическое лечение 1 процедура (3 кат.)",
        price: "330",
    },
    {
        code: "20.40",
        name: "Физиотерапевтическое лечение. Программа 10 процедур (1 поле)",
        price: "2057",
    },
    {
        code: "20.41",
        name: "	Физиотерапевтическое лечение. Программа 10 процедур (2 поля)",
        price: "2662",
    },
    {
        code: "20.42",
        name: "Физиотерапевтическое лечение. Программа 7 процедур (1 поле)",
        price: "935",
    },
    {
        code: "20.43",
        name: "Физиотерапевтическое лечение. Программа 7 процедур (2 поле)",
        price: "1265",
    }
];
