export const priceInfoUltrasoundDiagnostics = [
    {
        code: "18.1",
        name: "Ультразвуковое исследование одного органа брюшной полости (печень, селезенка, поджелудочная железа, желчный пузырь)	",
        price: "650",
    },
    {
        code: "18.3",
        name: "	Ультразвуковое исследование почек и надпочечник",
        price: "1000",
    },
    {
        code: "18.4",
        name: "Ультразвуковое исследование почек и надпочечников и мочевого пузыря",
        price: "1100",
    },
    {
        code: "18.5",
        name: "Ультразвуковое исследование мочевого пузыря с определением остаточной мочи",
        price: "660",
    },
    {
        code: "18.6",
        name: "ТРУЗИ",
        price: "1100",
    },
    {
        code: "18.7",
        name: "ТРУЗИ, мочевой пузырь с определением остаточной мочи",
        price: "1300",
    },
    {
        code: "18.9",
        name: "Ультразвуковое исследование мошонки с доплерографией семенного канатика",
        price: "1000",
    },
    {
        code: "18.10",
        name: "Ультразвуковое исследование щитовидной железы с региональными лимфоузлами",
        price: "950",
    },
    {
        code: "18.11",
        name: "Ультразвуковое исследование молочных желез  с региональными лимфоузлами",
        price: "1050",
    },
    {
        code: "18.12",
        name: "Ультразвуковое исследование мягких тканей",
        price: "800",
    },
    {
        code: "18.13",
        name: "Ультразвуковое исследование слюнных желез",
        price: "800",
    },
    {
        code: "18.14",
        name: "	Ультразвуковое исследование вилочковой железы",
        price: "800",
    },
    {
        code: "18.15",
        name: "Ультразвуковое исследование органов малого таза (матка, придатки)",
        price: "990",
    },
    {
        code: "18.16",
        name: "Фолликулогенез",
        price: "750",
    },
    {
        code: "18.17",
        name: "Цервикометрия",
        price: "700",
    },
    {
        code: "18.18",
        name: "Доплерография в акушерстве",
        price: "800",
    },
    {
        code: "18.19",
        name: "Ультразвуковое исследование по беременности сроком до 11 недель",
        price: "1100",
    },
    {
        code: "18.20",
        name: "Ультразвуковое исследование по беременности 1 триместр",
        price: "1350",
    },
    {
        code: "18.21",
        name: "Ультразвуковое исследование по беременности 2 триместр",
        price: "1500",
    },
    {
        code: "18.22",
        name: "Ультразвуковое исследование по беременности 3 триместр",
        price: "1650",
    },
    {
        code: "18.23",
        name: "Ультразвуковое исслед. беременности без оценки аномалии плода",
        price: "1250",
    },
    {
        code: "18.24",
        name: "Ультразвуковое исследование гайморовых пазух (справа и слева)",
        price: "600",
    },
    {
        code: "18.25",
        name: "Ультразвуковое исследование гайморовых и лобных  пазух",
        price: "950",
    },
    {
        code: "18.26",
        name: "Ультразвуковое исследование лобных пазух  (справа и слева)",
        price: "550",
    },
    {
        code: "18.27",
        name: "Ультразвуковое исследование плевральных полостей",
        price: "550",
    },
    {
        code: "18.28",
        name: "Эхокардиография",
        price: "1350",
    },
    {
        code: "18.29",
        name: "Эхокардиография (ведущий специалист)",
        price: "1550",
    },
    {
        code: "18.30",
        name: "Доплерография сосудов головного мозга и шеи",
        price: "2350",
    },
    {
        code: "18.32",
        name: "Доплерография сосудов шеи",
        price: "1250",
    },
    {
        code: "18.33",
        name: "Доплерография сосудов шеи с пробами",
        price: "1350",
    },
    {
        code: "18.34",
        name: "Допплерография сосудов орбиты и глазного яблока (2 глаза)",
        price: "1400",
    },
    {
        code: "18.35",
        name: "Нейросонография (дети до 1 года)",
        price: "1000",
    },
    {
        code: "18.36",
        name: "Доплерография структур головного мозга без сосудов (до 18 лет)",
        price: "750",
    },
    {
        code: "18.37",
        name: "Доплерография вен верхних или нижних конечностей (2 конечности)",
        price: "2000",
    },
    {
        code: "18.38",
        name: "Доплерография артерий верхних или нижних конечностей (2 конечности)",
        price: "2200",
    },
    {
        code: "18.39",
        name: "Доплерография вен одной конечности",
        price: "1000",
    },
    {
        code: "18.40",
        name: "Доплерография артерий одной конечности",
        price: "1100",
    },
    {
        code: "18.41",
        name: "Доплерография сосудов почек",
        price: "1250",
    },
    {
        code: "18.42",
        name: "Ультразвуковое исследование глазного яблока и орбиты",
        price: "1150",
    },
    {
        code: "18.43",
        name: "	Ультразвуковое исследование 1 группы лимфатических узлов",
        price: "750",
    },
    {
        code: "18.44",
        name: "Доплерография структур головного мозга с сосудами",
        price: "1200",
    },
    {
        code: "18.45",
        name: "Доплерография брюшного отдела аорты и её ветвей",
        price: "1550",
    },
    {
        code: "18.46",
        name: "Ультразвуковое исследование сосудов ПЧ",
        price: "1800",
    },
    {
        code: "18.47",
        name: "УЗИ печени и желчного пузыря с функциональными пробами",
        price: "1000",
    },
    {
        code: "18.48",
        name: "УЗИ тазобедренных суставов (до 1 года)",
        price: "900",
    },
    {
        code: "18.49",
        name: "УЗИ одного сустава",
        price: "1000",
    },
    {
        code: "18.50",
        name: "УЗИ двух суставов одной группы",
        price: "1750",
    },
    {
        code: "18.51",
        name: "	Ультразвуковое исследование органов брюшной полости комплексное (печень, желчный пузырь, поджелудочная железа, селезенка)",
        price: "1350",
    },
    {
        code: "18.52",
        name: "Ультразвуковое исследование органов брюшной полости и почек комплексное (печень, желчный пузырь, поджелудочная железа, селезенка, почки и надпочечники)",
        price: "1550",
    },
];
