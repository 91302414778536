export const priceInfoSurgeon = [
    {
        code: "22.1",
        name: "Прием врача - хирурга первичный",
        price: "950",
    },
    {
        code: "22.2",
        name: "Прием врача-хирурга повторный",
        price: "800",
    },
    {
        code: "22.3",
        name: "Консультация врача хирурга для коррекции лечения",
        price: "600",
    },
    {
        code: "22.4",
        name: "Медикаментозная блокада при эпикондилите",
        price: "750",
    },
    {
        code: "22.5",
        name: "Медикаментозная блокада при пяточной шпоре",
        price: "900",
    },
    {
        code: "22.6",
        name: "Параартикулярная блокада",
        price: "900",
    },
    {
        code: "22.7",
        name: "Хирургическая обработка послеоперационной раны со снятием швов",
        price: "600",
    },
    {
        code: "22.8",
        name: "Первичная хирургическая обработка раны без наложения швов",
        price: "1100",
    },
    {
        code: "22.9",
        name: "Первичная хирургическая обработка раны с наложением швов",
        price: "1650",
    },
    {
        code: "22.10",
        name: "Удаление  лигатур послеоперационного рубца",
        price: "700",
    },
    {
        code: "22.11",
        name: "Удаление ногтевой пластины",
        price: "1200",
    },
    {
        code: "22.12",
        name: "	Вскрытие панариция",
        price: "1350",
    },
    {
        code: "22.13",
        name: "Вскрытие и дренирование абсцесса, флегмоны, нагноившейся атеромы",
        price: "1200",
    },
    {
        code: "22.14",
        name: "Инфильтрационная анестезия",
        price: "550",
    },
    {
        code: "22.15",
        name: "Внутрисуставная инъекция(с предварительной блокадой,препарат пациента)",
        price: "700",
    },
    {
        code: "22.16",
        name: "Внутисуставная пункция",
        price: "600",
    },
    {
        code: "22.17",
        name: "Пункция бурсита",
        price: "600",
    },
    {
        code: "22.18",
        name: "Радиохирургическая удаление новообразования 1 категории сложности",
        price: "1450",
    },
    {
        code: "22.19",
        name: "Радиохирургическая удаление новообразования 2 категории сложности",
        price: "2000",
    },
    {
        code: "22.20",
        name: "Радиохирургическая удаление новообразования 3  категории сложности",
        price: "3000",
    },
    {
        code: "22.21",
        name: "Удаление новообразования диаметром до 5 мм (одна единица)",
        price: "700",
    },
    {
        code: "22.22",
        name: "Удаление новообразования диаметром до 5 мм (более 3-х единиц, за одну единицу)",
        price: "600",
    },
    {
        code: "22.23",
        name: "Удаление новообразования диаметром более 5 мм (одна единица)",
        price: "1550",
    },
    {
        code: "22.24",
        name: "Удаление новообразования диаметром до 10 мм с наложением швов (одна единица)",
        price: "1750",
    },
    {
        code: "22.25",
        name: "Удаление новообразования диаметром более 10 мм с наложением швов (одна единица)",
        price: "2200",
    },
    {
        code: "22.26",
        name: "	Наложение асептической повязки малой",
        price: "300",
    },
    {
        code: "22.27",
        name: "	Наложение асептической повязки большой",
        price: "450",
    },
    {
        code: "22.28",
        name: "Наложение мазевой повязки",
        price: "400",
    },
    {
        code: "22.29",
        name: "Перевязка гнойной раны",
        price: "700",
    },
    {
        code: "22.30",
        name: "	Пункция мягких тканей",
        price: "550",
    }
];
