export const priceInfoGynecologist = [
    {
        code: "2.1",
        name: "Прием врача акушера-гинеколога первичный (ведущий специалист)",
        price: "1100",
    },
    {
        code: "2.2",
        name: "Прием врача акушера-гинеколога повторный (ведущий специалист)",
        price: "900",
    },
    {
        code: "2.3",
        name: "Прием врача гинеколога первичный",
        price: "1000",
    },
    {
        code: "2.4",
        name: "Прием врача гинеколога повторный",
        price: "800",
    },
    {
        code: "2.5",
        name: "Первичный прием  врача акушера-гинеколога по беременности",
        price: "1300",
    },
    {
        code: "2.6",
        name: "Повторный  прием  врача акушера-гинеколога по беременности",
        price: "1100",
    },
    {
        code: "2.7",
        name: "Прием репродуктолога",
        price: "2750",
    },
    {
        code: "2.8",
        name: "Консультация врача акушера -гинеколога для коррекции лечения",
        price: "600",
    },
    {
        code: "2.9",
        name: "Введение внутриматочной спирали",
        price: "1900",
    },
    {
        code: "2.10",
        name: "Забор материала врачом на онкоцитологическое исследование",
        price: "350",
    },
    {
        code: "2.11",
        name: "Подбор и установка акушерского пессария",
        price: "1150",
    },
    {
        code: "2.12",
        name: "Парацервикальная анестезия",
        price: "660",
    },
    {
        code: "2.13",
        name: "Удаление единичного образования ( кандиломы)",
        price: "1500",
    },
    {
        code: "2.14",
        name: "Удаление единичного образования более 1 см",
        price: "2000",
    },
    {
        code: "2.15",
        name: "Радиоволновая коагуляция шейки матки",
        price: "3000",
    },
    {
        code: "2.16",
        name: "Обработка шейки матки раствором солковагина ( лечение патологии шейки матки)",
        price: "2100",
    },
    {
        code: "2.17",
        name: "Внутриматочное введение препарата ( 1 процедура)",
        price: "1300",
    },
    {
        code: "2.18",
        name: "Внутриматочное введение препарата канюлей Брауна ( 1 процедура)",
        price: "1650",
    },
    {
        code: "2.19",
        name: "Внутриматочное введение препарата канюлей Брауна ( 5  процедур)",
        price: "5500",
    },
    {
        code: "2.20",
        name: "Введение препарата Импланон (препарат пациента)",
        price: "2530",
    },
    {
        code: "2.21",
        name: "Удаление препарата Импланон",
        price: "2530",
    },
    {
        code: "2.22",
        name: "Внутриматочное введение аутоплазмы",
        price: "2530",
    },
    {
        code: "2.23",
        name: "Комплексное лечение синехий вульвы",
        price: "2200",
    },
    {
        code: "2.24",
        name: "Обработка влагалища ( ванночка)",
        price: "450",
    },
    {
        code: "2.25",
        name: "Комплекс «Медикаментозное прерывание беременности»",
        price: "9350",
    },
    {
        code: "2.26",
        name: "Эхогистероскопия",
        price: "4400",
    },
    {
        code: "2.27",
        name: "Ультразвуковое сопрововождение Эхогистероскопии",
        price: "1100",
    },
    {
        code: "2.28",
        name: "Инсеминация",
        price: "22000",
    },
    {
        code: "2.29",
        name: "Криодеструкция эрозии шейки матки",
        price: "2860",
    },
    {
        code: "2.30",
        name: "Амниотест полосками ТМ",
        price: "900",
    },
    {
        code: "2.31",
        name: "Амниотест полосками Амнишур",
        price: "3000",
    },
    {
        code: "2.32",
        name: "Кольпоскопия",
        price: "1150",
    },
    {
        code: "2.33",
        name: "Биопсия шейки матки аппаратная",
        price: "2500",
    },
    {
        code: "2.34",
        name: "Биопсия шейки матки конхотомная",
        price: "1900",
    },
    {
        code: "2.35",
        name: "Аспирационная биопсия полости матки",
        price: "1900",
    },
    {
        code: "2.36",
        name: "Полипэктомия шейки матки",
        price: "1900",
    },
    {
        code: "2.37",
        name: "Удаление внутриматочной спирали",
        price: "1400",
    },
    {
        code: "2.38",
        name: "Оформление на ЭКО (подготовка выписки, по результатам анализов проведение ВК с последующим формированием документов",
        price: "5200",
    }
];
