export const priceInfoTherapist = [
    {
        code: "16.1",
        name: "Прием первичный врача- терапевта",
        price: "950",
    },
    {
        code: "16.2",
        name: "Прием повторный врача- терапевта",
        price: "900",
    },
    {
        code: "16.3",
        name: "Консультация врача терапевта для коррекции лечения",
        price: "600",
    },
];
