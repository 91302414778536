import React from "react";
import "./PriceListComponent.css";

export const OfertaComponent = () => {
    return (
        <div className="OfertaComponent">
            <div className="OfertaComponent__inner">
                <h3>Прайс-лист не является публичной офертой</h3>
            </div>
        </div>
    );
};
